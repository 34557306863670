import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced: true,
    state(){
        return {
            maxValues: {},
            carTypes: [],
            booking: {
                price:{}
            },
            streetsHeen: {data:{},metadata:{}},
            streetsTerug: {data:{},metadata:{}}
        };
    },
    mutations,
    actions,
    getters
}