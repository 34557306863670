export default {
    setMaxValues(state, payload){
        state.maxValues = payload;
    },

    setCarTypes(state, payload){
        state.carTypes = payload;
    },

    setBookingPrice(state, payload){
        state.booking.price = payload;
    },

    setStreetsHeen(state, payload){
        state.streetsHeen = payload;
    },

    setStreetsTerug(state, payload){
        state.streetsTerug = payload;
    }
}