<template>
  <div class="waiting">
    <div class="waiting__content">
      <img src="@/assets/image/logo-billo-express.svg" alt="Billo logo">
      <h1>Express</h1>
      <p>Binnenkort beschikbaar | Bientôt disponible | Coming soon</p>
      <h2>Info?</h2>
      <p>Tel: <a href="tel:+3251702645">+3251702645</a></p>
      <p>Email: <a href="mailto:info@billo.be">info@billo.be</a></p>
      <p>Wiedauwkaai 98, 9000 Gent, België</p>
      <p>BE 0450 825 019</p>
      <p><a href="https://billo.be">www.billo.be</a></p>
    </div>
  </div>

</template>

<script>
export default {
  name: "WaitingPage"
}
</script>