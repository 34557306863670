<template>
  <div class="search-select">
    <input type="text" :class="'input search-select__input ' + this.classname" :placeholder="this.placeholder" v-model="search" :readonly="activeItem">
    <fa icon="times" v-if="activeItem" class="search-select__input__clear" @click="clearSelection"></fa>
    <div class="search-select__options" v-if="search && !activeItem && filteredItems.length">
      <div class="search-select__options__option" v-for="item in filteredItems" :key="`search--${item}`"
           @click="selectItem(item)">{{ this.label ? item[this.label] : item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchSelect",
  props: {
    items: {required: true},
    initValue:{},
    label: {},
    property: {},
    classname: {},
    placeholder:{}
  },
  computed: {
    filteredItems() {
      const items = [...this.items];
      const prop = this.property;
      if (!this.search) {
        return items;
      }
      return items.filter(i => prop ? i[prop].toLowerCase().match(this.search.toLowerCase()) : i.toLowerCase().match(this.search.toLowerCase()));
    }
  },
  watch: {
    search(){
      if(this.filteredItems && this.filteredItems.length === 1 && this.filteredItems[0][this.property].toLowerCase() === this.search.toLowerCase()){
        this.selectItem(this.filteredItems[0]);
      }
    }
  },
  data() {
    return {
      search: "",
      activeItem: null,
    };
  },

  created(){
    if(this.initValue){
      this.search = this.initValue;
      this.activeItem = this.items.find(i => this.property?i[this.property] === this.initValue: i === this.initValue);
    }
  },

  methods: {
    selectItem(item) {
      this.activeItem = item;
      this.search = this.property?item[this.property]:item;
      this.$emit('street-changed',item);
    },
    clearSelection(){
      this.search = "";
      this.activeItem = null;
      this.$emit('street-changed',null);
    }
  }
}
</script>