<template>
  <div class="page page--wide register">
    <h1>{{ $t('register.title') }}</h1>
    <form action="" class="register__form" @submit.prevent="submitRegister">
      <label class="checkbox">
        <b>{{ $t('account.companyaccount') }}</b>
        <input type="checkbox" v-model="input.iscompany">
        <span class="checkbox__checkmark"></span>
      </label>
      <div class="register__form__content">
        <div class="register__form__column">
          <div class="register__form__field">
            <label>{{ $t('booking.firstname') }}</label>
            <input class="input " :class="{'error':!fnValid}" type="text" @input="fnValid=true"
                   :name="Math.round(Math.random() * Date.now())"
                   v-model="input.firstname" :placeholder="$t('booking.firstname')" autocomplete="off"/>
          </div>
          <div class="register__form__field">
            <label>{{ $t('booking.name') }}</label>
            <input class="input " :class="{'error':!lnValid}" type="text" @input="lnValid=true"
                   :name="Math.round(Math.random() * Date.now())"
                   v-model="input.lastname" :placeholder="$t('booking.name')" autocomplete="off"/>
          </div>
          <div class="register__form__field">
            <label>{{ $t('booking.email') }}</label>
            <input class="input " :class="{'error':!emailValid}" type="email" @input="emailValid=true"
                   :name="Math.round(Math.random() * Date.now())"
                   v-model="input.email" :placeholder="$t('booking.email')" autocomplete="off"/>
          </div>
          <div class="register__form__field">
            <label>{{ $t('booking.phone') }}</label>
            <input type="tel" class="input" v-model="input.phone" :placeholder="$t('booking.phone')">
          </div>
          <template v-if="input.iscompany">
            <div class="register__form__field">
              <label>{{ $t('account.vat') }}</label>
              <input class="input " :class="{'error':vatError || !vatValid}" type="text" @input="vatValid=true"
                     :name="Math.round(Math.random() * Date.now())"
                     v-model="input.vat" placeholder="BE0XXXXXXXXX" autocomplete="off"/>
            </div>
            <div class="register__form__field">
              <label>{{ $t('account.company') }}</label>
              <input class="input" :class="{'error':!companyValid}" type="text" @input="companyValid=true"
                     :name="Math.round(Math.random() * Date.now())"
                     v-model="input.company" :placeholder="$t('account.company')" autocomplete="off"/>
            </div>
          </template>

        </div>
        <div class="register__form__column">
          <div class="register__form__field">
            <label>{{ $t('booking.street') }} + {{ $t('booking.nr') }}</label>
            <input class="input street" :class="{'error':!streetValid}" type="text" @input="streetValid=true"
                   :name="Math.round(Math.random() * Date.now())"
                   v-model="input.street" :placeholder="$t('booking.street')" autocomplete="off"/>
            <input class="input nr" :class="{'error':!nrValid}" type="text" @input="nrValid=true"
                   :name="Math.round(Math.random() * Date.now())"
                   v-model="input.streetnr" :placeholder="$t('booking.nr')" autocomplete="off"/>
          </div>
          <div class="register__form__field">
            <label>{{ $t('booking.postal') }} + {{ $t('booking.city') }}</label>
            <input class="input city" :class="{'error':!zipValid}" type="text" @input="zipValid=true"
                   :name="Math.round(Math.random() * Date.now())"
                   v-model="input.zip" :placeholder="$t('booking.postal')" autocomplete="off"/>
            <input class="input city" :class="{'error':!cityValid}" type="text" @input="cityValid=true"
                   :name="Math.round(Math.random() * Date.now())"
                   v-model="input.city" :placeholder="$t('booking.city')" autocomplete="off"/>
          </div>
          <div class="register__form__field">
            <label>{{ $t('account.country') }}</label>
            <select class="select" v-model="input.country">
              <option value="BE">Belgium</option>
              <option value="FR">France</option>
              <option value="NL">Netherlands</option>
              <option value="LU">Luxembourg</option>
              <option value="DE">Germany</option>
              <option value="AF">Afghanistan</option>
              <option value="AX">Åland Islands</option>
              <option value="AL">Albania</option>
              <option value="DZ">Algeria</option>
              <option value="AS">American Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AQ">Antarctica</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AR">Argentina</option>
              <option value="AM">Armenia</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BS">Bahamas</option>
              <option value="BH">Bahrain</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BY">Belarus</option>
              <option value="BE">Belgium</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia, Plurinational State of</option>
              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BV">Bouvet Island</option>
              <option value="BR">Brazil</option>
              <option value="IO">British Indian Ocean Territory</option>
              <option value="BN">Brunei Darussalam</option>
              <option value="BG">Bulgaria</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodia</option>
              <option value="CM">Cameroon</option>
              <option value="CA">Canada</option>
              <option value="CV">Cape Verde</option>
              <option value="KY">Cayman Islands</option>
              <option value="CF">Central African Republic</option>
              <option value="TD">Chad</option>
              <option value="CL">Chile</option>
              <option value="CN">China</option>
              <option value="CX">Christmas Island</option>
              <option value="CC">Cocos (Keeling) Islands</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoros</option>
              <option value="CG">Congo</option>
              <option value="CD">Congo, the Democratic Republic of the</option>
              <option value="CK">Cook Islands</option>
              <option value="CR">Costa Rica</option>
              <option value="CI">Côte d'Ivoire</option>
              <option value="HR">Croatia</option>
              <option value="CU">Cuba</option>
              <option value="CW">Curaçao</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DK">Denmark</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypt</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estonia</option>
              <option value="ET">Ethiopia</option>
              <option value="FK">Falkland Islands (Malvinas)</option>
              <option value="FO">Faroe Islands</option>
              <option value="FJ">Fiji</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="GF">French Guiana</option>
              <option value="PF">French Polynesia</option>
              <option value="TF">French Southern Territories</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgia</option>
              <option value="DE">Germany</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GR">Greece</option>
              <option value="GL">Greenland</option>
              <option value="GD">Grenada</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GG">Guernsey</option>
              <option value="GN">Guinea</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haiti</option>
              <option value="HM">Heard Island and McDonald Islands</option>
              <option value="VA">Holy See (Vatican City State)</option>
              <option value="HN">Honduras</option>
              <option value="HK">Hong Kong</option>
              <option value="HU">Hungary</option>
              <option value="IS">Iceland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesia</option>
              <option value="IR">Iran, Islamic Republic of</option>
              <option value="IQ">Iraq</option>
              <option value="IE">Ireland</option>
              <option value="IM">Isle of Man</option>
              <option value="IL">Israel</option>
              <option value="IT">Italy</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="JE">Jersey</option>
              <option value="JO">Jordan</option>
              <option value="KZ">Kazakhstan</option>
              <option value="KE">Kenya</option>
              <option value="KI">Kiribati</option>
              <option value="KP">Korea, Democratic People's Republic of</option>
              <option value="KR">Korea, Republic of</option>
              <option value="KW">Kuwait</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="LA">Lao People's Democratic Republic</option>
              <option value="LV">Latvia</option>
              <option value="LB">Lebanon</option>
              <option value="LS">Lesotho</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libya</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="MO">Macao</option>
              <option value="MK">Macedonia, the former Yugoslav Republic of</option>
              <option value="MG">Madagascar</option>
              <option value="MW">Malawi</option>
              <option value="MY">Malaysia</option>
              <option value="MV">Maldives</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="MH">Marshall Islands</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MU">Mauritius</option>
              <option value="YT">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesia, Federated States of</option>
              <option value="MD">Moldova, Republic of</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolia</option>
              <option value="ME">Montenegro</option>
              <option value="MS">Montserrat</option>
              <option value="MA">Morocco</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibia</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NL">Netherlands</option>
              <option value="NC">New Caledonia</option>
              <option value="NZ">New Zealand</option>
              <option value="NI">Nicaragua</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NU">Niue</option>
              <option value="NF">Norfolk Island</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="NO">Norway</option>
              <option value="OM">Oman</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestinian Territory, Occupied</option>
              <option value="PA">Panama</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PH">Philippines</option>
              <option value="PN">Pitcairn</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="RE">Réunion</option>
              <option value="RO">Romania</option>
              <option value="RU">Russian Federation</option>
              <option value="RW">Rwanda</option>
              <option value="BL">Saint Barthélemy</option>
              <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="MF">Saint Martin (French part)</option>
              <option value="PM">Saint Pierre and Miquelon</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="ST">Sao Tome and Principe</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SN">Senegal</option>
              <option value="RS">Serbia</option>
              <option value="SC">Seychelles</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SX">Sint Maarten (Dutch part)</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SO">Somalia</option>
              <option value="ZA">South Africa</option>
              <option value="GS">South Georgia and the South Sandwich Islands</option>
              <option value="SS">South Sudan</option>
              <option value="ES">Spain</option>
              <option value="LK">Sri Lanka</option>
              <option value="SD">Sudan</option>
              <option value="SR">Suriname</option>
              <option value="SJ">Svalbard and Jan Mayen</option>
              <option value="SZ">Swaziland</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="SY">Syrian Arab Republic</option>
              <option value="TW">Taiwan, Province of China</option>
              <option value="TJ">Tajikistan</option>
              <option value="TZ">Tanzania, United Republic of</option>
              <option value="TH">Thailand</option>
              <option value="TL">Timor-Leste</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TN">Tunisia</option>
              <option value="TR">Turkey</option>
              <option value="TM">Turkmenistan</option>
              <option value="TC">Turks and Caicos Islands</option>
              <option value="TV">Tuvalu</option>
              <option value="UG">Uganda</option>
              <option value="UA">Ukraine</option>
              <option value="AE">United Arab Emirates</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States</option>
              <option value="UM">United States Minor Outlying Islands</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VU">Vanuatu</option>
              <option value="VE">Venezuela, Bolivarian Republic of</option>
              <option value="VN">Viet Nam</option>
              <option value="VG">Virgin Islands, British</option>
              <option value="VI">Virgin Islands, U.S.</option>
              <option value="WF">Wallis and Futuna</option>
              <option value="EH">Western Sahara</option>
              <option value="YE">Yemen</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
          </div>
        </div>
        <div class="register__form__column">
          <div class="register__form__field">
            <label for="username">{{ $t('account.username') }}</label>
            <input class="input" :class="{'error':!unameValid}" id="username" type="text"
                   :name="Math.round(Math.random() * Date.now())"
                   v-model="input.user_name" :placeholder="$t('account.username')"
                   @input="duplicate=false"
                   autocomplete="off"/>
            <p v-if="duplicate" class="error">{{ $t('register.duplicate') }}</p>
          </div>
          <div class="register__form__field">
            <label for="pass1">{{ $t('login.password') }} {{ $t('register.minchars') }}</label>
            <input class="input " :class="{'error':!passValid}" id="pass1" type="password" @input="passValid=true"
                   :name="Math.round(Math.random() * Date.now())"
                   v-model="input.user_password" :placeholder="$t('login.password')"
                   autocomplete="new-password"/>
          </div>
          <div class="register__form__field">
            <label for="pass2">{{ $t('register.confirmpassword') }}</label>
            <input class="input" :class="{'error':!passValid}" id="pass2" type="password" @input="passValid=true"
                   :name="Math.round(Math.random() * Date.now())"
                   v-model="passCheck" :placeholder="$t('login.password')"
                   autocomplete="off"/>
          </div>
          <div class="register__form__field">
            <label class="checkbox checkbox--small" :class="{'checkbox--error':!privacyValid}">{{ $t('booking.gdpr1') }}
              <a :href="'https://www.privalex.be/nl/bedrijven/8a8078f562'" target="_blank">{{ $t('booking.gdpr2') }}</a>
              <input type="checkbox" v-model="privacy">
              <span class="checkbox__checkmark"></span>
            </label>
          </div>
          <div class="register__form__field">
            <label class="checkbox checkbox--small" :class="{'checkbox--error':!termsValid}">{{ $t('booking.terms1') }}
              <router-link to="/terms" target="_blank">{{ $t('booking.terms2') }}</router-link>
              <input type="checkbox" v-model="termsCompliant">
              <span class="checkbox__checkmark"></span>
            </label>
          </div>
          <div class="register__form__controls">
            <button class="button" :class="{'button--disabled':registering}"
                    type="button"
                    @click.prevent="submitRegister">{{ $t('register.submit') }}
            </button>
          </div>
        </div>
      </div>
      <div class="register__form__errors">
        <p class="error" v-if="!nrValid || !streetValid || !zipValid || !cityValid">{{ $t('errors.address') }}</p>
        <p class="error" v-if="!fnValid">{{ $t('errors.firstname') }}</p>
        <p class="error" v-if="!lnValid">{{ $t('errors.lastname') }}</p>
        <p class="error" v-if="!emailValid">{{ $t('errors.email') }}</p>
        <p class="error" v-if="!phoneValid">{{ $t('errors.phone') }}</p>
        <template v-if="input.iscompany">
          <p class="error" v-if="!companyValid">{{ $t('errors.company') }}</p>
          <p class="error" v-if="!vatValid">{{ $t('errors.vat') }}</p>
        </template>
        <p class="error" v-if="!unameValid">{{ $t('errors.username') }}</p>
        <p class="error" v-if="!passValid">{{ $t('errors.password') }}</p>
        <p class="error" v-if="!privacy && !privacyValid">{{ $t('errors.gdpr') }}</p>
        <p class="error" v-if="!termsCompliant && !termsValid">{{ $t('errors.terms') }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import formvalidate from "@/mixins/formvalidate";

export default {
  name: "Register",

  components: {},

  mixins: [formvalidate],

  data() {
    return {
      registering: false,
      input: {
        iscompany: false,
        user_name: "",
        user_password: "",
        user_type: "user",
        user_id_ext: "",
        email: "",
        firstname: "",
        lastname: "",
        phone: "",
        company: "",
        vatcountry: "",
        vat: "",
        street: "",
        streetnr: "",
        zip: "",
        city: "",
        country: ""
      },
      passCheck: "",
      privacy: false,
      termsCompliant: false,
      registerActive: false,
      phoneValid: true,
      vatError: false,
      duplicate: false,
      fnValid: true,
      lnValid: true,
      emailValid: true,
      unameValid: true,
      privacyValid: true,
      termsValid: true,
      companyValid: true,
      vatValid: true,
      passValid: true,
      streetValid: true,
      nrValid: true,
      cityValid: true,
      zipValid: true
    };
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  },
  watch:{
    isAuthenticated(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.push('/account');
      }
    }
  },

  methods: {
    async submitRegister() {
      this.registering = true;
      this.input.vatcountry = null;
      if (this.input.iscompany) {

        const response = await this.$store.dispatch('user/validateBtw', this.input.vat);
        console.log(response);
        if (response.valid) {
          this.vatValid = true;
          this.input.vatcountry = response.countryCode;
        }
      } else {
        this.input.vatcountry = "";
        this.input.vat = "";
      }

      if (!this.checkValid()) {
        this.registering = false;
        return;
      }
      try {
        const register = await this.$store.dispatch('user/registerUser', this.input);
        if(register.data === 'duplicate_user'){
          this.duplicate = true;
        }

      }catch(error){
        console.log(error);
      }
      this.registering = false;
    },

    resetValid() {
      this.duplicate = false;
      this.phoneValid = true;
      this.fnValid = true;
      this.lnValid = true;
      this.emailValid = true;
      this.unameValid = true;
      this.companyValid = true;
      this.vatValid = true;
      this.passValid = true;
      this.streetValid = true;
      this.nrValid = true;
      this.cityValid = true;
      this.zipValid = true;
    },

    checkValid() {
      this.resetValid();
      this.phoneValid = !!this.input.phone;
      this.fnValid = !!this.input.firstname;
      this.lnValid = !!this.input.lastname;
      this.emailValid = this.mailValidate(this.input.email);
      this.unameValid = !!this.input.user_name;
      this.companyValid = this.input.iscompany ? !!this.input.company : true;
      this.vatValid = this.iscompany ? !!this.input.vatcountry && !!this.input.vat : true;
      this.passValid = this.input.user_password && this.input.user_password.toString().length >= 8 && this.input.user_password === this.passCheck;
      this.streetValid = !!this.input.street;
      this.nrValid = !!this.input.streetnr;
      this.cityValid = !!this.input.city;
      this.zipValid = !!this.input.zip;
      this.privacyValid = this.privacy;
      this.termsValid = this.termsCompliant;
      return this.phoneValid
          && this.fnValid
          && this.lnValid
          && this.emailValid
          && this.unameValid
          && this.companyValid
          && this.vatValid
          && this.passValid
          && this.streetValid
          && this.nrValid
          && this.cityValid
          && this.zipValid;
    },
  }
}
</script>