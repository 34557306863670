<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="footer__top__container">
        <div class="footer__top__column">
          <router-link to="/"><img width="123" src="../assets/image/logo-billo-express.svg" alt="Billo express logo">
          </router-link>
          <div class="footer__payments">
            <p>{{ $t('footer.payment') }}</p>
            <img src="../assets/image/payment/visa@2x.png" alt="Visa">
            <img src="../assets/image/payment/maestro@2x.png" alt="Maestro">
            <img src="../assets/image/payment/mastercard@2x.png" alt="Mastercard">
            <img src="../assets/image/payment/paypal@2x.png" alt="PayPal">
            <img src="../assets/image/payment/cirrus@2x.png" alt="Cirrus">
            <p><span class="disclaimer">{{$t('footer.disclaimer')}}</span></p>
          </div>
        </div>
        <div class="footer__top__column">
          <p class="footer__title">{{ $t('contact.phone') }}</p>
          <p><a class="footer__cta" href="tel:+32092531343">092 531 343</a><br>{{ $t('contact.booking_new') }}
            <br><span class="footer__small">({{ $t('contact.pay') }})</span></p>
          <p><a class="footer__cta" href="tel:+3251702645">051 702 645</a><br>{{ $t('contact.booking_ext') }}</p>
          <p></p>
          <p><a class="footer__cta" href="mailto:contact@billo.be">contact@billo.be</a></p>
        </div>
        <div class="footer__top__column">
          <p>{{ $t('footer.navigate') }}</p>
          <ul>
            <li>
              <router-link to="/">{{ $t('nav.home') }}</router-link>
            </li>
            <li>
              <router-link to="/about">{{ $t('nav.about') }}</router-link>
            </li>
            <li>
              <router-link to="/account">{{ $t('nav.account') }}</router-link>
            </li>
            <li>
              <router-link to="/contact">{{ $t('nav.contact') }}</router-link>
            </li>
            <li>
              <router-link to="/pricing">{{ $t('nav.pricing') }}</router-link>
            </li>

          </ul>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      {{ $t('footer.text') }} -
      <a href="https://www.privalex.be/nl/bedrijven/8a8078f562" target="_blank">{{ $t('nav.privacy') }}</a>
      -
      <router-link to="/terms">{{ $t('nav.terms') }}</router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter"
}
</script>

<style scoped>

</style>