<template>
  <div class="page page--wide pricing">
    <h1>{{ $t('pricing.title') }}</h1>
    <div v-if="$i18n.locale==='nl'">
      <p>
        Om de kosten voor jou zo laag mogelijk te houden werken wij met een dynamische prijsberekening die afhankelijk
        is van verschillende parameters. Tijdens het opstellen van uw order zal de prijs zich steeds aanpassen
        afhankelijk van uw keuzes.
      </p>
      <p>Bij het berekenen van het benodigde wagentype en de bijhorende prijs wordt rekening gehouden met volgende
        parameters:</p>
      <ul class="list">
        <li>Lading</li>
        <li>Extra's</li>
        <li>Kilometerprijs afhankelijk van externe factoren (brandstofprijzen enz.)</li>
      </ul>
      <p>Onze tarieven geldig sinds <b>15/12/2020</b>:</p>
      <p><b>Wagentypes</b></p>
      <table>
        <tbody>
        <tr>
          <th>Type</th>
          <th>Omschrijving</th>
          <th>€/km</th>
          <th>minimumprijs (€)</th>
          <th>Max volume</th>
          <th># Pallets</th>
          <th>Max gewicht (kg)</th>
        </tr>
        <tr>
          <td>A</td>
          <td>Personenwagen</td>
          <td>1.10</td>
          <td>35</td>
          <td>75 x 60 x 35cm</td>
          <td>0</td>
          <td>25</td>
        </tr>
        <tr>
          <td>B</td>
          <td>Kleine bestelwagen</td>
          <td>1.30</td>
          <td>40</td>
          <td>125 x 100 x 110cm</td>
          <td>1</td>
          <td>250</td>
        </tr>
        <tr>
          <td>C</td>
          <td>Bestelwagen</td>
          <td>1.50</td>
          <td>60</td>
          <td>160 x 130 x 140cm</td>
          <td>1</td>
          <td>500</td>
        </tr>
        <tr>
          <td>D</td>
          <td>Bestelwagen XL</td>
          <td>1.75</td>
          <td>70</td>
          <td>400 x 130 x 165cm</td>
          <td>2</td>
          <td>1000</td>
        </tr>
        <tr>
          <td>E</td>
          <td>Meubelwagen</td>
          <td>2.05</td>
          <td>80</td>
          <td>400 x 240 x 185cm</td>
          <td>6</td>
          <td>1250</td>
        </tr>
        </tbody>
      </table>
      <p><b>Extra's</b></p>
      <table>
        <tbody>
        <tr>
          <th>Omschrijving</th>
          <th>Prijs (€)</th>
        </tr>
        <tr>
          <td>Hulp laden</td>
          <td>30</td>
        </tr>
        <tr>
          <td>Hulp lossen</td>
          <td>30</td>
        </tr>
        <tr>
          <td>Papieren factuur</td>
          <td>12.30</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="$i18n.locale==='fr'">
      <p>
        Pour garder les coûts aussi bas que possible pour vous, nous travaillons avec un calcul de prix dynamique qui dépend
        de plusieurs paramètres. Le prix s'ajustera toujours lors de la préparation de votre commande
        selon vos choix.
      </p>
      <p>Les paramètres suivants sont pris en compte lors du calcul du type de wagon requis et du prix associé:</p>
      <ul class="list">
        <li>Cargaison</li>
        <li>Extras</li>
        <li>Prix au kilométrage en fonction de facteurs externes (prix du carburant, etc.)</li>
      </ul>
      <p>Nos tarifs valables depuis <b>15/12/2020</b>:</p>
      <p><b>Types de voitures</b></p>
      <table>
        <tbody>
        <tr>
          <th>Type</th>
          <th>Description</th>
          <th>€/km</th>
          <th>prix minimum (€)</th>
          <th>Max volume</th>
          <th># Pallets</th>
          <th>Poids max (kg)</th>
        </tr>
        <tr>
          <td>A</td>
          <td>Automobile</td>
          <td>1.10</td>
          <td>35</td>
          <td>75 x 60 x 35cm</td>
          <td>0</td>
          <td>25</td>
        </tr>
        <tr>
          <td>B</td>
          <td>Camionnette petite</td>
          <td>1.30</td>
          <td>40</td>
          <td>125 x 100 x 110cm</td>
          <td>1</td>
          <td>250</td>
        </tr>
        <tr>
          <td>C</td>
          <td>Camionnette</td>
          <td>1.50</td>
          <td>60</td>
          <td>160 x 130 x 140cm</td>
          <td>1</td>
          <td>500</td>
        </tr>
        <tr>
          <td>D</td>
          <td>Camionnette XL</td>
          <td>1.75</td>
          <td>70</td>
          <td>400 x 130 x 165cm</td>
          <td>2</td>
          <td>1000</td>
        </tr>
        <tr>
          <td>E</td>
          <td>Camion de meubles</td>
          <td>2.05</td>
          <td>80</td>
          <td>400 x 240 x 185cm</td>
          <td>6</td>
          <td>1250</td>
        </tr>
        </tbody>
      </table>
      <p><b>Extras</b></p>
      <table>
        <tbody>
        <tr>
          <th>Description</th>
          <th>Prix (€)</th>
        </tr>
        <tr>
          <td>Aide au chargement</td>
          <td>30</td>
        </tr>
        <tr>
          <td>Aide au déchargement</td>
          <td>30</td>
        </tr>
        <tr>
          <td>Facture papier</td>
          <td>12.30</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="$i18n.locale==='en'">
      <p>
        To keep our price as low as possible for you, we work with a dynamic price calculation that depends on multiple
        factors. The price will always adapt to your choices when you place an order using our tool.
      </p>
      <p>When calculating the required vehicle type and the corresponding price, the following is taken into
        account:</p>
      <ul class="list">
        <li>Cargo</li>
        <li>Extras</li>
        <li>Price per kilometre depending on external factors (fuel prices, etc.)</li>
      </ul>
      <p>Our rates valid since <b>15/12/2020</b>:</p>
      <p><b>Vehicle types</b></p>
      <table>
        <tbody>
        <tr>
          <th>Type</th>
          <th>Description</th>
          <th>€/km</th>
          <th>minimum price (€)</th>
          <th>Max volume</th>
          <th># Pallets</th>
          <th>Max weight (kg)</th>
        </tr>
        <tr>
          <td>A</td>
          <td>Passenger car</td>
          <td>1.10</td>
          <td>35</td>
          <td>75 x 60 x 35cm</td>
          <td>0</td>
          <td>25</td>
        </tr>
        <tr>
          <td>B</td>
          <td>Small van</td>
          <td>1.30</td>
          <td>40</td>
          <td>125 x 100 x 110cm</td>
          <td>1</td>
          <td>250</td>
        </tr>
        <tr>
          <td>C</td>
          <td>Van</td>
          <td>1.50</td>
          <td>60</td>
          <td>160 x 130 x 140cm</td>
          <td>1</td>
          <td>500</td>
        </tr>
        <tr>
          <td>D</td>
          <td>Van XL</td>
          <td>1.75</td>
          <td>70</td>
          <td>400 x 130 x 165cm</td>
          <td>2</td>
          <td>1000</td>
        </tr>
        <tr>
          <td>E</td>
          <td>Furniture Van</td>
          <td>2.05</td>
          <td>80</td>
          <td>400 x 240 x 185cm</td>
          <td>6</td>
          <td>1250</td>
        </tr>
        </tbody>
      </table>
      <p><b>Extras</b></p>
      <table>
        <tbody>
        <tr>
          <th>Description</th>
          <th>Price (€)</th>
        </tr>
        <tr>
          <td>Help with loading</td>
          <td>30</td>
        </tr>
        <tr>
          <td>Help with unloading</td>
          <td>30</td>
        </tr>
        <tr>
          <td>Paper invoice</td>
          <td>12.30</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing",
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>