let timer;
export default {
    async getToken(context) {
        const url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_AUTH}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'client_credentials',
                scope: 'billoexpress',
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET
            })
        });
        const responseData = await response.json();

        if (!response.ok || responseData.error) {
            const error = new Error(
                responseData.error_description || 'Failed to authenticate. Check your client data.'
            );
            throw error;
        }

        const expiresIn = +responseData.expires_in * 1000;
        const expirationDate = new Date().getTime() + expiresIn;

        localStorage.setItem('token', responseData.access_token);
        localStorage.setItem('tokenExpiration', expirationDate);

        timer = setTimeout(function() {
            context.dispatch('getToken');
        }, expiresIn);

        context.commit('setUser', {
            token: responseData.access_token,
            userName: null
        });
    },

    async login( context, payload){
        const url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_AUTH}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'password',
                scope: 'billoexpress',
                code: 'code',
                username: payload.username,
                password: payload.password,
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET
            })
        });
        const responseData = await response.json();

        if (!response.ok || responseData.error) {
            const error = new Error(
                responseData.error_description || 'Failed to authenticate. Check your login data.'
            );
            throw error;
        }

        const expiresIn = +responseData.expires_in * 1000;
        const expirationDate = new Date().getTime() + expiresIn;


        localStorage.setItem('token', responseData.access_token);
        localStorage.setItem('refreshToken', responseData.refresh_token);
        localStorage.setItem('userName', payload.username);
        localStorage.setItem('userId',  responseData.user_id);
        localStorage.setItem('tokenExpiration', expirationDate);

        clearTimeout(timer);

        timer = setTimeout(function() {
            context.dispatch('refreshToken');
        }, expiresIn);

        context.commit('setUser', {
            token: responseData.access_token,
            userName: payload.username,
            userId:  responseData.user_id
        });
        context.dispatch('user/getUserData');
    },

    async refreshToken(context) {
        const userName = localStorage.getItem('userName');
        const userId = localStorage.getItem('userId');
        const refreshtoken = localStorage.getItem('refreshToken');
        if(!refreshtoken || !userName){
            context.dispatch('autoLogout');
            return;
        }
        let url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_AUTH}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'refresh_token',
                refresh_token: refreshtoken,
                scope: 'billoexpress',
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET
            })
        });
        const responseData = await response.json();

        if (!response.ok || responseData.error) {
            const error = new Error(
                responseData.message || 'Failed to re-authenticate.'
            );
            context.dispatch('autoLogout');
            throw error;
        }

        const expiresIn = +responseData.expires_in * 1000;
        const expirationDate = new Date().getTime() + expiresIn;

        localStorage.setItem('token', responseData.access_token);
        localStorage.setItem('refreshToken', responseData.refresh_token);
        localStorage.setItem('tokenExpiration', expirationDate);

        clearTimeout(timer);

        timer = setTimeout(function() {
            context.dispatch('refreshToken');
        }, expiresIn);

        context.commit('setUser', {
            token: responseData.access_token,
            userName: userName,
            userId: userId
        });
        context.dispatch('user/getUserData');
    },

    tryLogin(context) {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');
        const userName= localStorage.getItem('userName');
        const userId= localStorage.getItem('userId');
        const tokenExpiration = localStorage.getItem('tokenExpiration');

        const expiresIn = +tokenExpiration - new Date().getTime();

        if(!refreshToken){
            context.dispatch('getToken')
            return;
        }

        if ((!expiresIn || expiresIn < 0) && refreshToken) {
            context.dispatch('refreshToken');
            return;
        }

        timer = setTimeout(function() {
            context.dispatch('refreshToken');
        }, expiresIn);

        if (token && userName) {
            context.commit('setUser', {
                token: token,
                userName: userName,
                userId: userId
            });
            context.dispatch('user/getUserData');
        }
    },

    logout(context) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.removeItem('tokenExpiration');

        clearTimeout(timer);

        context.commit('setUser', {
            token: null,
            userName: null,
            userId: null
        });
        context.dispatch('user/clearUserData');
        context.dispatch('getToken');

    },
    autoLogout(context) {
        context.dispatch('logout');
        context.commit('setAutoLogout');
    }
};