<template>
<div class="page">
  <div class="page__block">
    <h2>{{ $t('orderfailed.title') }}</h2>
    <p>{{ $t('orderfailed.text') }}</p>
    <p>{{ $t('backto') }}
      <router-link to="/">Home</router-link>
    </p>
  </div>
</div>
</template>

<script>
export default {
  name: "OrderFailed"
}
</script>