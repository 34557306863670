import Sha1 from 'crypto-js/sha1';

export default {
    methods: {
        prepareIngenicoForm(id, email, price, locale, queryparams = "") {
            let lang = 'en_GB';
            if (locale === 'nl') {
                lang = 'nl_NL';
            } else if (locale === 'fr') {
                lang = 'fr_FR';
            }
            const shacode = process.env.VUE_APP_PAYMENT_SHACODE;
            const confirmUrl = process.env.VUE_APP_PAYMENT_CONFIRMURL;
            const failUrl = process.env.VUE_APP_PAYMENT_FAILURL + queryparams;
            const cancelUrl = process.env.VUE_APP_PAYMENT_CANCELURL + queryparams;
            const actionUrl = process.env.VUE_APP_PAYMENT_ACTIONURL;
            const pspid = process.env.VUE_APP_PAYMENT_PSPID;
            const currency = process.env.VUE_APP_PAYMENT_CURRENCY;
            const paymentPrice = Math.round(parseFloat(price) * 100); //centimes
            let shaString = '';
            shaString += 'ACCEPTURL=' + confirmUrl + shacode;
            shaString += 'AMOUNT=' + paymentPrice + shacode;
            shaString += 'CANCELURL=' + cancelUrl + shacode;
            shaString += 'CURRENCY=' + currency + shacode;
            shaString += 'DECLINEURL=' + failUrl + shacode;
            shaString += 'EMAIL=' + email + shacode;
            shaString += 'EXCEPTIONURL=' + failUrl + shacode;
            shaString += 'LANGUAGE=' + lang + shacode;
            shaString += 'ORDERID=' + id + shacode;
            shaString += 'PSPID=' + pspid + shacode;
            return {
                id,
                email,
                confirmUrl,
                failUrl,
                cancelUrl,
                actionUrl,
                pspid,
                currency,
                lang,
                price: paymentPrice,
                sign: Sha1(shaString).toString()
            };
        }
    }
}