import {createRouter, createWebHashHistory} from 'vue-router';
import store from '../store';
import Home from '@/views/Home.vue';
import Account from "@/views/Account";
import Login from "@/views/Login";
import Contact from "@/views/Contact";
import Pricing from "@/views/Pricing";
import Terms from "@/views/Terms";
import About from "@/views/About";
import Register from "@/views/Register";
import PasswordReset from "@/views/PasswordReset";
import PasswordRecovery from "@/views/PasswordRecovery";
import OrderConfirmed from "@/views/OrderConfirmed";
import OrderCanceled from "@/views/OrderCanceled";
import OrderFailed from "@/views/OrderFailed";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
        meta: {requiresAuth: true}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {requiresUnauth: true}
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {requiresUnauth: true}
    },
    {
        path: '/reset-password',
        name: 'PasswordReset',
        component: PasswordReset
    },
    {
        path: '/password-recovery',
        name: 'PasswordRecovery',
        component: PasswordRecovery,
        meta: {requiresUnauth: true}
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: Pricing
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms
    },
    {
        path:'/order-confirmed',
        name:'OrderConfirmed',
        component: OrderConfirmed
    },
    {
        path:'/order-canceled',
        name:'OrderCanceled',
        component: OrderCanceled
    },
    {
        path:'/order-failed',
        name:'OrderFailed',
        component: OrderFailed
    },
    {
        path:'/order-confirmed',
        name:'OrderConfirmed',
        component: OrderConfirmed
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: () => import('../views/NotFound')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/login');
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/account');
  } else {
    next();
  }
});

export default router
