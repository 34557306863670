<template>
  <div class="page page--wide account">
    <h1>{{ $t('account.account') }}</h1>
    <div class="account__content" v-if="userData">
      <div class="account__info">
        <h2>Info</h2>
        <p>{{ `${userData.user.firstname} ${userData.user.lastname}` }}</p>
        <p>{{ `${userData.user.email}` }}</p>
        <p>{{ `${userData.user.phone}` }}</p>
        <template v-if="userData.user.iscompany">
          <p><b>{{ `${userData.user.company}` }}</b></p>
          <p>{{ `${userData.user.vatcountry} ${userData.user.vat}` }}</p>
        </template>
        <button class="button" @click="editUser">{{ $t('edit') }}</button>
        <button class="button" @click="resetPassword" v-if="!userData.user.id_facebook && !userData.user.id_google">
          {{ $t('resetpassword.title') }}
        </button>
        <button class="button button--decline" @click="logout">{{ $t('account.logout') }}</button>
        <p v-if="isResetSent" class="success">{{ $t('resetpassword.checkmail') }}</p>
        <p v-if="resetError" class="error">{{ $t('resetpassword.error') }}</p>
      </div>
      <div class="account__data">
        <div class="account__addresses">
          <h2>{{ $t('account.addresses') }}</h2>
          <ul class="list">
            <li v-for="item in userData.addresses" :key="'address--'+item.id" @click="editAddress(item)">
              {{ `${item.name} - ${item.street} ${item.streetnr} - ${item.zip} ${item.city} (${item.country})` }}
              <br>
              <template v-if="item.defaultaddress"><span
                  class="special">{{ $t('account.defaultpickup') }}</span></template>
              <template v-if="item.invoiceaddress"><span
                  class="special">{{ $t('account.defaultinvoice') }}</span></template>
              <template v-if="!item.billoaddress"><span class="error">{{ $t('account.addressinvalid') }}</span>
              </template>
            </li>
          </ul>
          <button class="button button--accept" @click="newAddress">{{ $t('account.newaddress') }} +</button>
        </div>
        <div class="account__orders">
          <h2>{{ $t('account.orders') }}</h2>
          <div class="account__orders__pagination" v-if="ordersPages>1">
            <button class="svgbutton" :class="{'svgbutton--disabled':currentpage<2}" @click="prevPage"><fa icon="arrow-left"></fa></button>
            <span>{{`${currentpage}/${ordersPages}`}}</span>
            <button class="svgbutton" :class="{'svgbutton--disabled':currentpage===ordersPages}" @click="nextPage"><fa icon="arrow-right"></fa></button>
          </div>
          <div class="account__orders__results" id="result-body">
            <table class="table" v-if="hasOrders">
              <thead>
              <tr class="table__header">
                <th class="table__header__column">ID</th>
                <th class="table__header__column">{{ $t('booking.date') }}</th>
                <th class="table__header__column">{{ $t('booking.from') }}</th>
                <th class="table__header__column">{{ $t('account.to') }}</th>
                <th class="table__header__column">{{ $t('booking.cargo') }}</th>
                <th class="table__header__column">{{ $t('booking.vehicle') }}</th>
                <th class="table__header__column"></th>
              </tr>
              </thead>
              <tbody class="table__body">
              <tr class="table__body__item" v-for="item in orders" :key="`order--${item.ID}`">
                <td class="table__body__item__column">{{ item.LINKDISPATCH }}</td>
                <td class="table__body__item__column">{{ item.DAT }}</td>
                <td class="table__body__item__column">{{ item.VANNAAM }}</td>
                <td class="table__body__item__column">{{ item.NAARNAAM }}</td>
                <td class="table__body__item__column">
                  <span v-for="(cargo, index) in item.CARGODATA.cargo" :key="`cargo--${item.ID}${index}${cargo.L}${cargo.G}${cargo.naam}`"><b>{{cargo.naam}}</b>, x{{cargo.aantal}} ({{cargo.P}})<br></span>
                </td>
                <td class="table__body__item__column">{{item.CARGODATA.calcprice.vehicletype.wagentype}}</td>
                <!-- TODO pdf genereren en tonen -->
                <td class="table__body__item__column"><a :href="`${linkRoot}${item.LINKDISPATCH}`" target="_blank" class="svgbutton"><fa icon="eye"></fa></a></td>
              </tr>
              </tbody>
            </table>
            <div v-else class="no-results">
              Geen resultaten.
            </div>
            <transition name="fade">
              <div class="loading" v-if="isLoading">
                <fa icon="spinner" class="loader"></fa>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <teleport to="#app">
      <div class="modal" v-if="editProfile">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="editProfile=null">
            <fa icon="times"></fa>
          </button>
          <div class="modal__title">{{ $t('account.editinfo') }}</div>
          <div class="modal__body">
            <p>{{ $t('account.editinfotext') }}</p>
            <form action="" class="form" @submit.prevent="saveUser">
              <div class="form__column">
                <div class="form__field">
                  <label for="p-fname">{{ $t('booking.firstname') }}</label>
                  <input name="p-fname" :placeholder="$t('booking.firstname')" id="p-fname" type="text" class="input"
                         v-model="editProfile.firstname">
                </div>
                <div class="form__field">
                  <label for="p-lname">{{ $t('booking.name') }}</label>
                  <input name="p-lname" :placeholder="$t('booking.name')" id="p-lname" type="text" class="input"
                         v-model="editProfile.lastname">
                </div>
                <div class="form__field">
                  <label for="p-email">{{ $t('booking.email') }}</label>
                  <input name="p-email" :placeholder="$t('booking.email')" id="p-email" type="email" class="input"
                         v-model="editProfile.email">
                </div>
                <div class="form__field">
                  <label for="p-phone">{{ $t('booking.phone') }}</label>
                  <input name="p-phone" :placeholder="$t('booking.phone')" id="p-phone" type="tel" class="input"
                         v-model="editProfile.phone">
                </div>
              </div>
            </form>
          </div>
          <div class="modal__controls">
            <p class="error" v-if="profileError">{{ profileError }}</p>
            <button class="button button--accept" :class="{'button--disabled':userEditing}" @click="saveUser">
              {{ $t('account.save') }}
            </button>
          </div>
        </div>
      </div>
      <div class="modal" v-if="activeAddress">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="activeAddress=null">
            <fa icon="times"></fa>
          </button>
          <div class="modal__title">{{ $t(activeAddress.id ? 'account.editaddress' : 'account.newaddress') }}</div>
          <div class="modal__body">
            <p>{{ $t(activeAddress.id ? 'account.editaddresstext' : 'account.newaddresstext') }}</p>
            <form action="" class="form" @submit.prevent="saveAddress">
              <div class="form__column">
                <div class="form__field">
                  <label>{{ $t('booking.name') }}</label>
                  <input type="text" class="input" v-model="activeAddress.name" :placeholder="$t('booking.name')">
                </div>
                <div class="form__field">
                  <label>{{ $t('booking.street') }}</label>
                  <input type="text" class="input" v-model="activeAddress.street" :placeholder="$t('booking.street')">
                </div>
                <div class="form__field">
                  <label>{{ $t('booking.nr') }}</label>
                  <input type="text" class="input" v-model="activeAddress.streetnr" :placeholder="$t('booking.nr')">
                </div>
                <div class="form__field">
                  <label>{{$t('booking.postal')}}</label>
                  <input type="text" class="input" v-model="activeAddress.zip" :placeholder="$t('booking.postal')">
                </div>
                <div class="form__field">
                  <label>{{$t('booking.city')}}</label>
                  <input type="text" class="input" v-model="activeAddress.city" :placeholder="$t('booking.city')">
                </div>
                <div class="form__field">
                  <label>{{$t('account.country')}}</label>
                  <select class="select" v-model="activeAddress.country">
                    <option value="BE">Belgium</option>
                    <option value="FR">France</option>
                    <option value="NL">Netherlands</option>
                    <option value="LU">Luxembourg</option>
                    <option value="DE">Germany</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AX">Åland Islands</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AQ">Antarctica</option>
                    <option value="AG">Antigua and Barbuda</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia, Plurinational State of</option>
                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                    <option value="BA">Bosnia and Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="IO">British Indian Ocean Territory</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CA">Canada</option>
                    <option value="CV">Cape Verde</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CD">Congo, the Democratic Republic of the</option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CI">Côte d'Ivoire</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CW">Curaçao</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="TF">French Southern Territories</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GG">Guernsey</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HM">Heard Island and McDonald Islands</option>
                    <option value="VA">Holy See (Vatican City State)</option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran, Islamic Republic of</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IM">Isle of Man</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JE">Jersey</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KP">Korea, Democratic People's Republic of</option>
                    <option value="KR">Korea, Republic of</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">Lao People's Democratic Republic</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macao</option>
                    <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">Micronesia, Federated States of</option>
                    <option value="MD">Moldova, Republic of</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="ME">Montenegro</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PS">Palestinian Territory, Occupied</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Réunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="BL">Saint Barthélemy</option>
                    <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="MF">Saint Martin (French part)</option>
                    <option value="PM">Saint Pierre and Miquelon</option>
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome and Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="RS">Serbia</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SX">Sint Maarten (Dutch part)</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                    <option value="SS">South Sudan</option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SJ">Svalbard and Jan Mayen</option>
                    <option value="SZ">Swaziland</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syrian Arab Republic</option>
                    <option value="TW">Taiwan, Province of China</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania, United Republic of</option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad and Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks and Caicos Islands</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                    <option value="US">United States</option>
                    <option value="UM">United States Minor Outlying Islands</option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">Venezuela, Bolivarian Republic of</option>
                    <option value="VN">Viet Nam</option>
                    <option value="VG">Virgin Islands, British</option>
                    <option value="VI">Virgin Islands, U.S.</option>
                    <option value="WF">Wallis and Futuna</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
                <template v-if="activeAddress.id">
                  <label  v-if="!isDefault" class="checkbox checkbox--small">{{$t('account.defaultpickup')}}
                    <input type="checkbox" v-model="activeAddress.defaultaddress">
                    <span class="checkbox__checkmark"></span>
                  </label>
                  <label  v-if="!isInvoice" class="checkbox checkbox--small">{{$t('account.defaultinvoice')}}
                    <input type="checkbox" v-model="activeAddress.defaultinvoice">
                    <span class="checkbox__checkmark"></span>
                  </label>
                </template>
              </div>
            </form>
          </div>
          <div class="modal__controls">
            <button class="button button--accept" @click="saveAddress">{{ $t('account.save') }}</button>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import formvalidate from "@/mixins/formvalidate";

export default {
  name: "Account",
  data() {
    return {
      resetError: false,
      isResetSent: false,
      editProfile: false,
      activeAddress: null,
      userEditing: false,
      adressEditing: false,
      profileError: null,
      addressError: null,
      isDefault:false,
      isInvoice:false,

      ordersError: null,
      isLoading: false,
      currentpage: 1,
      pageSize: 5
    };
  },
  mixins: [formvalidate],
  computed: {
    linkRoot(){
      return `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/templates/billoexpressconfirmation.php?id=`;
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    hasOrders(){
      return this.$store.getters['user/hasOrders'];
    },
    orders(){
      return this.$store.getters['user/orders'];
    },
    ordersPages(){
      return this.$store.getters['user/ordersPages'];
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loadOrders();
      this.$store.dispatch('user/getUserData');
    },

    async loadOrders(){
      const data = {
        PageSize: this.pageSize,
        PageNumber: this.currentpage
      }
      this.ordersError = null;
      this.isLoading = true;
      try {
        this.$store.dispatch('user/getUserOrders', data);
      } catch(e){
        this.ordersError = e;
      }
      const resultbody = document.getElementById('result-body');
      if(resultbody){
        resultbody.scrollTop=0;
      }
      this.isLoading = false;
    },

    prevPage(){
      this.currentpage--;
      this.loadOrders();
    },
    nextPage(){
      this.currentpage++;
      this.loadOrders();
    },

    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/login');
    },
    async resetPassword() {
      this.isResetSent = false;
      this.resetError = false;
      const response = await this.$store.dispatch('user/recoverPassword', this.userData.user.email);
      if (response.status) {
        this.isResetSent = true;
        return;
      }
      this.resetError = true;
    },
    editUser() {
      this.editProfile = {...this.userData.user};
    },
    async saveUser() {
      this.profileError = null;
      const user = this.editProfile;
      if (!!user.firstname && !!user.lastname && !!user.phone && this.mailValidate(user.email)) {
        this.userEditing = true;
        try {
          await this.$store.dispatch('user/editUser', user);
          this.editProfile = null;
          this.fetchData();
        } catch (e) {
          this.profileError = 'error';
        }
        this.userEditing = false;
      } else {
        this.profileError = 'error!'
      }
    },

    editAddress(address) {
      this.activeAddress = {...address};
      this.isDefault = address.defaultaddress;
      this.isInvoice = address.invoiceaddress;
    },
    newAddress() {
      this.activeAddress = {
        billo_user_id: this.$store.getters['userId'],
        street: "",
        streetnr: "",
        zip: "",
        city: "",
        country: "BE",
        defaultaddress: 0,
        invoiceaddress: 0
      }
    },
    async saveAddress() {
      this.addressError = null;
      const adr = this.activeAddress;
      const adrValid = !!adr.street && !!adr.streetnr && !!adr.zip && !!adr.city && !!adr.country;
      if(!adrValid){
        this.addressError = 'error!';
        return;
      }
      let call = 'user/newAddress';
      if(adr.id){
        call = 'user/editAddress';
      }
      this.adressEditing = true;
      try{
        await this.$store.dispatch(call, adr);
        this.activeAddress = null;
        this.fetchData();
      }catch(e){
        this.addressError = 'error!';
      }
      this.adressEditing = false;
    }
  }
}
</script>