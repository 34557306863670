<template>
  <header class="header">
    <div class="header__top">
      <router-link to="/contact">{{ $t('nav.contact') }}</router-link>
      <router-link to="/account" v-if="isAuthenticated">{{ activeUser }} <fa class="icon icon--inline" icon="user-circle"></fa></router-link>
      <router-link to="/login" v-else>{{ $t('nav.login') }}</router-link>
      <LanguageSelector class="header__top__dropdown"></LanguageSelector>
    </div>
    <div class="header__bottom">
      <div class="header__bottom__content">
        <div class="header__bottom__content__menu">
          <router-link to="/">
            <img src="../assets/image/logo-billo-express.svg" width="123" alt="Billo Express logo">
          </router-link>
          <div class="header__bottom__content__menu__main">
            <router-link to="/">{{$t('nav.home')}}</router-link>
            <router-link to="/about">{{$t('nav.about')}}</router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LanguageSelector from "@/components/LanguageSelector";

export default {
  name: "TheHeader",
  components: {
    LanguageSelector
  },

  computed:{
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    activeUser() {
      return this.$store.getters.userName;
    }
  }
}
</script>