export default {
    maxValues(state){
        return state.maxValues;
    },

    carTypes(state){
        return state.carTypes;
    },
    hasCarTypes(state){
        return state.carTypes && state.carTypes.length;
    },

    bookingPrice(state){
        return state.booking.price;
    },

    streetsHeen(state){
        return state.streetsHeen;
    },
    hasStreetsHeen(state){
        return state.streetsHeen.data && state.streetsHeen.data.length;
    },

    streetsTerug(state){
        return state.streetsTerug;
    },
    hasStreetsTerug(state){
        return state.streetsTerug.data && state.streetsTerug.data.length;
    }
}