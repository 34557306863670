import { createStore } from 'vuex'
import authModule from './modules/auth';
import userModule from './modules/user';
import bookingModule from './modules/booking';

export default createStore({
  modules: {
    auth: authModule,
    user: userModule,
    booking: bookingModule
  }
})
