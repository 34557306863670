<template>
<div class="page page--wide terms">
  <h1>Voorwaarden</h1>
  <div class="app__content__full__content">
    <h2>1.Algemeen</h2>
    <p>Onderhavige algemene voorwaarden zijn van toepassing op alle offertes, bestellingen en diensten van de BV Huur
      Een Stuur, al dan niet handelend onder de handelsbenaming Billo, met zetel te 9000 Gent, Wiedauwkaai 98 en met
      ondernemingsnummer 0450.825.019 (hierna: HES), behoudens uitdrukkelijk andersluidend beding en met uitsluiting
      van de voorwaarden van de medecontractant. Als medecontractant wordt eenieder beschouwd die een contractuele
      band heeft met HES en de bestelling betaalt (hierna: Klant). Als Klant wordt tevens eenieder beschouwd die een
      bestelling plaatst, ongeacht of zij de prijs van de daaruitvloeiende opdracht al dan niet dient te betalen.</p>
    <p>Aangetekende brieven dienen steeds gericht te worden aan HES op bovenvernoemd adres. Andere schriftelijke
      communicatie dient gericht te worden aan het mailadres van HES (contact@billo.be).</p>
    <h2>2.Bestellingen en ritten</h2>
    <p>Bestellingen van ritten kunnen per mailbericht, telefoon of via het online reserveringssysteem aangevraagd
      worden. De aangeboden prijzen zijn vrijblijvend tot het moment van effectieve bestelling. Prijzen die aangeboden
      worden door middel van een offerte zijn maar geldig voor een periode van 30 dagen. De prijzen gelden enkel voor
      de opdracht zoals vermeld in de bestelling.</p>
    <p>Aangeboden prijzen zijn steeds afhankelijk van brandstofprijzen, reistijden, ophaal- en afleveradressen, volume en gewicht van de lading, etc. zodat deze niet toegepast
      kunnen worden op andere ritten. Indien een korting wordt verleend dan wordt deze korting slechts eenmalig te
      zijn verleend, tenzij anders vermeld. Alle péage of tol is altijd extra te betalen door de Klant, tenzij anders
      vermeld bij de offerte, reservatie of vervoersdocument.</p>
    <p>Er kan tot 2 uur op voorhand besteld worden, afhankelijk van het benodigde voertuigtype kan dit oplopen tot 4 uur.</p>
    <p>De bestelling is pas afgerond eens een bevestiging werd verstuurd naar de Klant. De rit die volgt uit een
      bestelling zal maar uitgevoerd worden eens de bestelling werd afgerond.</p>
    <h2>3.Verantwoordelijkheid Klant</h2>
    <p>De Klant is verantwoordelijk voor de correcte ingave en mededeling van alle gegevens zoals:</p>
    <ul class="list">
      <li>het mailadres,</li>
      <li>het ophaaladres,</li>
      <li>het afleveradres,</li>
      <li>tijdstip van ophalen (indien er niet gekozen wordt om het pakket zo snel mogelijk af te halen),</li>
      <li>het gewicht van de lading,</li>
      <li>het volume van de lading,</li>
      <li>het aantal stukken die de lading omvat,</li>
      <li>eventuele extra's zoals hulp bij het laden/lossen,</li>
      <li>eventuele hindernissen bij zowel het laden als het lossen van de lading,</li>
      <li>speciale wensen (zoals levering bij buren indien niemand aanwezig, speciale behandeling lading bv bijzonder breekbaar, etc.) ,</li>
      <li>contactgegevens van de afzender,</li>
      <li>wijzigingen,</li>
      <li>ieder ander gegeven dat redelijkerwijze relevant kan zijn voor de reservatie en/of de uitvoering van de
        rit.
      </li>
    </ul>
    <p>De Klant is tevens verantwoordelijk voor de vlotte bereikbaarheid van de plaats van ophaling en afhaling. De klant zorgt ook voor de nodige hulpmiddelen zoals een heftruck indien nodig voor zowel het laden en lossen van de lading. Enige vertraging
      hierdoor valt ten laste van de Klant. Daarenboven kan HES een extra vergoeding aanrekenen bij gebreke aan
      correcte mededeling van de nodige gegevens.</p>
    <p>Indien HES schade zou lijden doordat de plaats van ophaling of aflevering niet vlot bereikbaar bleek, zal de Klant HES voor
      de schade volledig vrijwaren.</p>
    <p>Bij ontvangst van het op te halen uur dient de Klant enigszins te controleren of dit een realistisch tijdstip
      is en geen kennelijke vergissing, in welk geval de Klant HES hiervan zo snel mogelijk op de hoogte brengt.</p>
    <p>Wanneer bepaalde wensen/zaken niet werden aangegeven bij de bestelling heeft HES het recht deze bijkomende
      wensen/zaken te weigeren uit te voeren of een bijkomende vergoeding aan te rekenen overeenkomstig de courante
      eenheidsprijzen. Dit is bijvoorbeeld het geval bij extra lading, etc. </p>
    <p>Bij een wijziging van het uur van het eigenlijke ophaling, adressen, etc. dient de Klant HES
      zo snel mogelijk hiervan op de hoogte te brengen.</p>
    <p>De Klant dient HES tevens op de hoogte te brengen bij enige vertraging of oponthoud door bepaalde
      omstandigheden zoals onder meer een douanecontrole, werken in de omgeving, etc.</p>
    <p>Er wordt van de Klant ook verwacht dat hij HES zo snel mogelijk contacteert wanneer blijkt dat de chauffeur
      niet aanwezig is op de afgesproken plaats op het afgesproken tijdstip.</p>
    <p>Iedere wijziging en/of correctie alsook nieuwe bestellingen dienen rechtstreeks doorgegeven worden aan HES.
      Indien de Klant deze zaken enkel doorgeeft aan de chauffeur kan het HES niet verweten worden hier geen rekening
      mee te houden.</p>
    <h2>4.Annulering/stopzetting</h2>
    <p>HES heeft het recht de opdracht te annuleren/stop te zetten, met onmiddellijke ingang en zonder
      ingebrekestelling, noch schadevergoeding in geval van staking van betaling of (aanvraag van) het faillissement
      door de Klant of bij vereffening of stopzetting van de activiteiten van de Klant of inden de Klant, ondanks
      schriftelijke ingebrekestelling waarbij een termijn van 7 kalenderdagen in acht wordt genomen, in gebreke blijft
      de verplichting na te komen. HES heeft eveneens steeds het recht de rit te annuleren in geval van overmacht.</p>
    <p>De Klant kan de bestelling schriftelijk annuleren. Annulering kan kosteloos tot 24 uur voor vertrek indien het een ophaling met een vaste ophaaltijd betreft. Annulering
      is in principe niet mogelijk vanaf 24 uur voor vertrek, of voor express-boekingen. In dit laatste geval wordt het volledige bedrag
      aangerekend.</p>
    <p>HES mag, zonder enige schadevergoeding voor de Klant, de rit geheel of gedeeltelijk annuleren indien
      er zich voor of tijdens de uitvoering van de rit overmachtssituaties en/of buitengewone omstandigheden voordoen,
      zijnde omstandigheden die HES bij bevestiging van de rit niet kende of kon kennen en die, indien HES ze wel had
      gekend, een geldige reden zouden zijn geweest om de rit niet uit te voeren. Buitengewone omstandigheden zijn
      onder meer vervoer van illegale personen of goederen, onvoorzienbare routewijzigingen, etc.</p>
    <p>Dit artikel en zijn bepalingen zijn van toepassing behoudens enige tegenstrijdige wettelijke dwingende
      bepaling.</p>
    <h2>5.Uitvoering</h2>
    <p>HES streeft er naar de Klant tijdig op te halen en te vervoeren doch is dit afhankelijk van externe factoren
      zoals onder meer extreem weer, sneeuw, ijzel, files, verkeersongevallen, etc. HES doet haar uiterste best zich
      aan te passen aan deze factoren en in de mate van het mogelijke te voorzien. In het kader van de
      verkeersveiligheid kan HES in uitzonderlijke omstandigheden er voor opteren de opdracht niet uit te voeren. In
      dergelijk geval zal het betaalde bedrag terugbetaald worden aan de Klant.</p>
    <p>HES kan niet aansprakelijk gesteld worden voor vergoeding van enige schade die het gevolg is van laattijdigheid
      door externe factoren zoals onder meer extreem weer, sneeuw, ijzel, files, verkeersongevallen, etc.</p>
    <p>HES zal naar best vermogen de passagiers vervoeren binnen de aangegeven tijdspanne. Dit laatste betreft een
      middelenverbintenis.</p>
    <p>HES heeft het recht een passagier, al dan niet op voorhand, te weigeren wanneer deze de verkeersveiligheid in
      het gedrang zou brengen en/of wanneer de passagiers in een gesteldheid verkeert die een ordentelijke rit in de
      weg zou kunnen staan.</p>
    <h2>6.Ophaling en afhaling</h2>
    <p>HES streeft er naar een richtuur van ophaling mee te delen aan de Klant bij het gebruik van de boekingsmodule of via mail afhankelijk van de timing van de bestelling binnen een redelijke termijn. Het concrete uur, indien het geen bestelling betreft die zo snel mogelijk dient uitgevoerd te worden, kan evenwel nog gewijzigd worden
      afhankelijk van de noodwendigheden van de organisatie van HES tot 24 uur voor de ophaling.</p>
    <p>Er wordt een richtuur meegedeeld wanneer de lading wordt afgehaald vanaf uw ophaallocatie. Het uur van ophalen dient uiteraard steeds correct te worden meegedeeld.
      Desgevallend streeft HES er naar een chauffeur op tijd beschikbaar te stellen. De chauffeur zal hierna 30 minuten wachten. Indien de Klant HES tijdig
      verwittigt, zal de chauffeur langer wachten maar zal de extra wachttijd aangerekend worden aan de gebruikelijke
      eenheidsprijzen.</p>
    <p>Om de ritten zo vlot mogelijk te laten verlopen en omdat de chauffeurs ook tijdig bij de volgende passagiers
      zouden kunnen zijn, wordt er van de Klant verwacht dat zij zorgen dat de lading klaarstaat op het afgesproken uur op de afgesproken ophaallocatie met de nodige personen en hulpmiddelen ter beschikking om te laden en dat de lading verwacht wordt op het afleveradres met de nodige personen hulpmiddelen ter beschikking om te lossen. Indien dit
      niet het geval zou zijn, kan de chauffeur langer wachten, in zoverre de planning dit toelaat. Er zal dan een
      wachttijd aangerekend worden aan de gebruikelijke eenheidsprijzen. Wanneer de planning het niet zou toelaten om
      de chauffeur te laten wachten op de passagiers kan HES het te laat komen van de passagiers beschouwen als een
      annulering die vergoed zal dienen te worden overeenkomstig de algemene voorwaarden.</p>
    <h2>7.Aansprakelijkheid HES</h2>
    <p>De aansprakelijkheid van HES kan geen aanleiding geven tot enige schadevergoeding, behalve in geval van
      opzettelijke of zware fout of bedrog van HES of haar aangestelden. </p>
    <p>Ten aanzien van Klanten die geen medecontractant zijn, kan HES niet rechtstreeks door deze aangesproken worden,
      behoudens enige dwingende wettelijke bepaling. Desgevallend zal deze klant zich dienen te richten tot de
      initiële opdrachtgever.</p>
    <p>De aansprakelijkheid van HES wordt steeds beperkt tot maximaal de waarde zoals vermeld op de bestelling met een
      maximum van € 2.500,00. HES kan nooit aansprakelijk gesteld worden voor indirecte schade, zoals maar niet
      beperkt tot, gederfde inkomsten, winsten, verlies van een klant, of eender welke gevolgschade, etc. Dit
      impliceert dat HES bijvoorbeeld bij het missen van een vlucht geen schade zal vergoeden die kan bestaan uit het
      betalen van een hotel, het boeken van een nieuwe (aansluitings)vlucht, etc.</p>
    <h2>8.Overmacht</h2>
    <p>In het geval van overmacht is elke partij bevrijd en niet gehouden tot nakoming van enige verbintenis jegens de
      wederpartij. Onder overmacht wordt verstaan de situatie waarin de uitvoering van de overeenkomst door één der
      partijen geheel of gedeeltelijk, al dan niet tijdelijk verhinderd wordt door omstandigheden buiten de wil van
      die partij. Zonder limitatief te willen zijn, wordt als geval van overmacht beschouwd: technische storingen aan
      het vervoermiddel die niet aan enige fout of nalatigheid van HES te wijten is, diefstal van het vervoermiddel,
      extreme weersomstandigheden die afbreuk doen aan de verkeersveiligheid, stakingen, pandemie, etc.</p>
    <h2>9.Betaling</h2>
    <p>De facturen zijn betaalbaar ten laatste op de vervaldag op het adres van HES, behoudens andersluidende
      afspraken. Wanneer een factuur niet tijdig betaald wordt, is van rechtswege en zonder enige ingebrekestelling,
      een verwijlinterest verschuldigd van 10%, alsook een forfaitaire schadevergoeding van 15% met een minimum van €
      125,00. Dit beding sluit geenszins aanvullende schadevergoedingen uit. Laattijdige betalingen worden eerst
      aangerekend op de verhogingen en dan pas op de hoofdsom.</p>
    <p>Protest tegen een factuur dient binnen de 8 dagen na ontvangst ervan per aangetekend schrijven te gebeuren op
      het adres van de maatschappelijke zetel van HES.</p>
    <h2>10.Geschillen</h2>
    <p>De ongeldigheid van een (gedeelte van een) bepaling heeft geen invloed op de geldigheid van de overige (
      gedeeltes van de) bepalingen.</p>
    <p>De rechtbanken van het arrondissement waar de maatschappelijke zetel van HES zich bevindt, zijn exclusief
      bevoegd om kennis te nemen van ieder geschil, behoudens enige dwingende wettelijke bepaling.</p>
    <p>Het Belgisch recht is van toepassing.</p>
  </div>
</div>
</template>

<script>
export default {
name: "Terms"
}
</script>