<template>
  <div class="page">
    <div class="page__block">
      <h2>{{ $t('ordercanceled.title') }}</h2>
      <p>{{ $t('ordercanceled.text') }}</p>
      <p>{{ $t('backto') }}
        <router-link to="/">Home</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderCanceled"
}
</script>