<template>
  <div class="booking" id="booking">
    <div class="booking__form booking__form--cta" v-if="currentStep===0">
      <div class="booking__form__warning" v-if="!isAuthenticated">
        <p>
          <router-link to="/login">{{ $t('login.submit') }}</router-link>
          {{ $t('or') }}
          <router-link to="/register">{{ $t('register.submit') }}</router-link>
          {{ $t('booking.logfirst') }}
        </p>
      </div>
      <h2>{{ $t('booking.iwant') }}</h2>
      <div class="booking__form__buttons">
        <button class="button button--cta" :class="{'button--disabled':!isAuthenticated}" @click="startBooking(true)">
          <fa icon="shipping-fast"></fa>
          {{ $t('booking.asap') }}
        </button>
        <button class="button button--cta" :class="{'button--disabled':!isAuthenticated}" @click="startBooking(false)">
          <fa icon="calendar-alt"></fa>
          {{ $t('booking.no-asap') }}
        </button>
      </div>
      <p><span class="disclaimer">{{ $t('booking.disclaimer1') }}</span>
      </p>
    </div>
    <div class="booking__form booking__form--cta" v-if="currentStep===1">
      <form action="" class="form" @submit.prevent="submitStep(1)">
        <template v-if="!booking.asap">
          <h2>{{ $t('booking.when') }}</h2>
          <div class="form__field">
            <label for="">{{ $t('booking.dateslot') }}</label>
            <DatePicker v-model="booking.date" inputFormat="dd/MM/yyyy" :lowerLimit="minDate"></DatePicker>
            <select class="select" v-model="booking.time">
              <option v-for="item in timeSlots" :value="item" :key="`time--${item}`">{{ item }}</option>
            </select>
          </div>
          <p><span class="disclaimer">{{ $t('booking.disclaimer2') }}</span>
          </p>
        </template>
        <h2>{{ $t('booking.from') }}</h2>
        <div class="form__field">
          <label class="checkbox checkbox--small">{{ $t('booking.account-address') }}
            <input type="radio" v-model="presetH" :value="true">
            <span class="checkbox__checkmark"></span>
          </label>
          <label class="checkbox checkbox--small">{{ $t('booking.free-address') }}
            <input type="radio" v-model="presetH" :value="false">
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
        <div class="form__field" v-if="presetH">
          <select class="select address" v-model="activeH">
            <option :value="null" disabled>-- {{ $t('booking.address') }} --</option>
            <option v-for="item in userAddresses.filter(a => activeT?a.id !== activeT.id:a)"
                    :key="`address--H--${item.id}`" :value="item">
              {{ `${item.name} - ${item.street} ${item.streetnr} - ${item.zip} ${item.city} (${item.country})` }}
            </option>
          </select>
        </div>
        <template v-if="!presetH">
          <div class="form__field">
            <label for="vn">{{ $t('booking.name') }}</label>
            <input id="vn" type="text" class="input" v-model="address1.name" :placeholder="$t('booking.name')">
          </div>
          <div class="form__field">
            <label for="pstv">{{ $t('booking.postal') }} + {{ $t('booking.city') }}</label>
            <input id="pstv" class="input" type="text" :placeholder="$t('booking.postal')" v-model="address1.zip">
            <input id="gemv" class="input" type="text" :placeholder="$t('booking.city')" readonly
                   :value="hasStreetsHeen?`${streetsHeen.metadata.citynames} (${streetsHeen.data[0].LAND})`:''">
            <p class="error" v-if="!zip1Valid">{{ $t('booking.outofbounds') }}</p>
          </div>
          <div class="form__field" v-if="hasStreetsHeen">
            <label for="streetH">{{ $t('booking.street') }} + {{ $t('booking.nr') }}</label>
            <SearchSelect :items="streetsHeen.data" :initValue="address1.street" :classname="'street'"
                          :placeholder="$t('booking.street')" :label="'STRAAT'" :property="'STRAAT'"
                          @street-changed="streetHInputChanged"></SearchSelect>
            <input type="text" class="input nr" :placeholder="$t('booking.nr')" v-model="address1.nr">
          </div>
        </template>
        <h2>{{ $t('account.to') }}</h2>
        <div class="form__field">
          <label class="checkbox checkbox--small">{{ $t('booking.account-address') }}
            <input type="radio" v-model="presetT" :value="true">
            <span class="checkbox__checkmark"></span>
          </label>
          <label class="checkbox checkbox--small">{{ $t('booking.free-address') }}
            <input type="radio" v-model="presetT" :value="false">
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
        <div class="form__field" v-if="presetT">
          <select class="select address" v-model="activeT">
            <option :value="null" disabled>-- {{ $t('booking.address') }} --</option>
            <option v-for="item in userAddresses.filter(a => activeH?a.id !== activeH.id:a)"
                    :key="`address--H--${item.id}`" :value="item">
              {{ `${item.name} - ${item.street} ${item.streetnr} - ${item.zip} ${item.city} (${item.country})` }}
            </option>
          </select>
        </div>
        <template v-if="!presetT">
          <div class="form__field">
            <label for="nn">{{ $t('booking.name') }}</label>
            <input id="nn" :placeholder="$t('booking.name')" type="text" class="input" v-model="address2.name">
          </div>
          <div class="form__field">
            <label for="pstn">{{ $t('booking.postal') }} + {{ $t('booking.city') }}</label>
            <input id="pstn" class="input" type="text" :placeholder="$t('booking.postal')" v-model="address2.zip">
            <input id="gemn" class="input" type="text" :placeholder="$t('booking.city')" readonly
                   :value="hasStreetsTerug?`${streetsTerug.metadata.citynames} (${streetsTerug.data[0].LAND})`:''">
          </div>
          <div class="form__field" v-if="hasStreetsTerug">
            <label for="streetT">{{ $t('booking.street') }} + {{ $t('booking.nr') }}</label>
            <SearchSelect :items="streetsTerug.data" :initValue="address2.street" :classname="'street'"
                          :placeholder="$t('booking.street')" :label="'STRAAT'" :property="'STRAAT'"
                          @street-changed="streetTInputChanged"></SearchSelect>
            <input type="text" class="input nr" :placeholder="$t('booking.nr')" v-model="address2.nr">
          </div>
        </template>
        <div class="form__controls">
          <button class="button" @click.prevent="currentStep--">{{ $t('previous') }}</button>
          <button class="button" :class="{'button--disabled':!step1Valid}" @click.prevent="submitStep(1)">{{
              $t('next')
            }}
          </button>
        </div>
      </form>
    </div>
    <div class="booking__form" v-else-if="currentStep===2">
      <h2>{{ $t('booking.cargo') }}</h2>
      <form action="" class="form" @submit.prevent="submitStep(2)">
        <div class="booking__form__buttons">
          <button class="button button--cta" @click.prevent="addCargo('pakket')">
            <fa icon="box"></fa>
            <fa class="plus" icon="plus"></fa>
            {{ $t('booking.pakket') }}
          </button>
          <button class="button button--cta" @click.prevent="addCargo('pallet')">
            <fa icon="pallet"></fa>
            <fa class="plus" icon="plus"></fa>
            {{ $t('booking.pallet') }}
          </button>
          <button class="button button--cta" @click.prevent="addCargo('document')">
            <fa icon="file-alt"></fa>
            <fa class="plus" icon="plus"></fa>
            {{ $t('booking.document') }}
          </button>
        </div>
        <p><b>{{ $t('booking.current-cargo') }}</b></p>
        <ul class="form__cargo" v-if="booking.cargo.length">
          <li class="form__cargo__item" v-for="(item,index) in booking.cargo" :key="`cargo--${item.G}${item.L}`">
            <fa class="icon icon--inline" icon="pallet" v-if="item.P==='pallet'"></fa>
            <fa class="icon icon--inline" icon="box" v-else-if="item.P==='pakket'"></fa>
            <fa class="icon icon--inline" icon="file-alt" v-else-if="item.P==='document'"></fa>
            <b>{{ item.naam }}</b>{{
              `, ${item.aantal}x ${item.P === 'document' ? '' : `, ${item.L}cm x ${item.B}cm x ${item.H}cm (${item.G}kg)`}`
            }}
            <fa icon="times" class="icon icon--inline error" @click.prevent="removeCargo(index)"></fa>
          </li>
        </ul>
        <p v-else>{{ $t('booking.no-cargo') }}</p>
        <p class="error" v-if="cargoError && booking.cargo.length">{{ $t('booking.error-cargo') }}</p>
        <h2>{{ $t('booking.extras') }}</h2>
        <label class="checkbox checkbox--small">{{ $t('booking.helpload') }}*
          <input type="checkbox" v-model="booking.extras.load">
          <span class="checkbox__checkmark"></span>
        </label>
        <label class="checkbox checkbox--small">{{ $t('booking.helpunload') }}*
          <input type="checkbox" v-model="booking.extras.unload">
          <span class="checkbox__checkmark"></span>
        </label>
        <label class="checkbox checkbox--small">{{ $t('booking.paperinvoice') }}
          <input type="checkbox" v-model="booking.extras.paperinvoice">
          <span class="checkbox__checkmark"></span>
        </label>
        <p><span class="disclaimer">{{ $t('booking.disclaimer3') }}</span>
        </p>
        <h2>{{ $t('booking.payment') }}</h2>
        <label class="checkbox checkbox--small">{{ $t('booking.creditcard') }}
          <input type="radio" v-model="booking.payment" value="CREDITCARD">
          <span class="checkbox__checkmark"></span>
        </label>
        <label class="checkbox checkbox--small">{{ $t('booking.invoice') }}
          <input type="radio" v-model="booking.payment" value="INVOICE">
          <span class="checkbox__checkmark"></span>
        </label>
        <h2>{{ $t('booking.termstitle') }}</h2>
        <label class="checkbox checkbox--small">{{ $t('booking.gdpr1') }}
          <a :href="'https://www.privalex.be/nl/bedrijven/8a8078f562'" target="_blank">{{ $t('booking.gdpr2') }}</a>
          <input type="checkbox" v-model="gdprCompliant">
          <span class="checkbox__checkmark"></span>
        </label>
        <label class="checkbox checkbox--small">{{ $t('booking.terms1') }}
          <router-link target="_blank" to="/terms">{{ $t('booking.terms2') }}</router-link>
          <input type="checkbox" v-model="termsCompliant">
          <span class="checkbox__checkmark"></span>
        </label>

        <label class="checkbox checkbox--small">{{ $t('booking.consent') }}
          <input type="checkbox" v-model="consentCompliant">
          <span class="checkbox__checkmark"></span>
        </label>
        <div class="form__controls">
          <button class="button" :class="{'button--disabled':isOrdering}" @click.prevent="currentStep--">
            {{ $t('previous') }}
          </button>
          <button class="button" :class="{'button--disabled':!step2Valid || isOrdering}" @click.prevent="submitStep(2)">
            {{ $t('booking.save') }}
          </button>
        </div>
      </form>
      <teleport to="#app">
        <div class="modal modal--cargo" v-if="cargoModalVisible">
          <div class="modal__wrapper">
            <button class="svgbutton modal__close error" @click="cargoModalVisible=false">
              <fa icon="times"></fa>
            </button>
            <div class="modal__title">{{ cargoItem.P }}</div>
            <div class="modal__body">
              <form action="" class="form" @submit.prevent="addCargoSubmit">
                <div class="form__column">
                  <div class="form__field">
                    <label>{{ $t('booking.description') }}</label>
                    <input type="text" class="input" :placeholder="$t('booking.description')" v-model="cargoItem.naam">
                  </div>
                  <div class="form__field">
                    <label>{{ $t('booking.amount') }}</label>
                    <input type="number"
                           @focusout="handleNumberInput($event, cargoItem,'aantal',1,cargoItem.P==='pallet'?maxValuesCargo.P:null)"
                           class="input"
                           :placeholder="$t('booking.amount')"
                           v-model.number="cargoItem.aantal">
                  </div>
                  <template v-if="cargoItem.P !== 'document'">
                    <div class="form__field">
                      <label>{{ $t('booking.length') }}</label>
                      <input type="number" @focusout="handleNumberInput($event, cargoItem,'L',0,maxValuesCargo.L)"
                             class="input" :placeholder="$t('booking.length')" v-model.number="cargoItem.L"
                             :readonly="cargoItem.P!=='pakket'">
                    </div>
                    <div class="form__field">
                      <label>{{ $t('booking.width') }}</label>
                      <input type="number" @focusout="handleNumberInput($event, cargoItem,'B',0,maxValuesCargo.B)"
                             class="input" :placeholder="$t('booking.width')" v-model.number="cargoItem.B"
                             :readonly="cargoItem.P!=='pakket'">
                    </div>
                    <div class="form__field">
                      <label>{{ $t('booking.height') }}</label>
                      <input type="number" @focusout="handleNumberInput($event, cargoItem,'H',0,maxValuesCargo.H)"
                             class="input" :placeholder="$t('booking.height')" v-model.number="cargoItem.H">
                    </div>
                    <div class="form__field">
                      <label>{{ $t('booking.weight') }}</label>
                      <input type="number" @focusout="handleNumberInput($event, cargoItem,'G',0,maxValuesCargo.G)"
                             class="input" :placeholder="$t('booking.weight')" v-model="cargoItem.G">
                    </div>
                  </template>
                </div>
              </form>
            </div>
            <div class="modal__controls">
              <button class="button button--accept" :class="{'button--disabled':!cargoItemValid}"
                      @click.prevent="addCargoSubmit">{{ $t('booking.add') }}
              </button>
            </div>
          </div>
        </div>
      </teleport>
    </div>
    <div class="booking__pricing" v-if="currentStep>1">
      <h2>{{ $t('booking.overview') }}</h2>
      <p v-if="!booking.asap">{{ $t('booking.date') }}: <b>{{ dateToString(booking.date) }}</b></p>
      <p v-else>ASAP</p>
      <h2>{{ $t('booking.from') }}</h2>
      <p><b>{{ address1.name }}</b></p>
      <p>{{ `${address1.street} ${address1.nr}, ${address1.zip} ${address1.city} (${address1.country})` }}</p>
      <p v-if="booking.asap && bookingPrice && bookingPrice.asap">{{ $t('booking.pickuptime') }}:
        <b>{{ $t('booking.now') }} - {{ bookingPrice.asap.time }}</b></p>
      <p v-else-if="!booking.asap">
        {{ $t('booking.pickuptime') }}: <b>{{ booking.time }}</b>
      </p>
      <p v-else>{{ $t('booking.cargoneeded') }}</p>
      <h2>{{ $t('account.to') }}</h2>
      <p><b>{{ address2.name }}</b></p>
      <p>{{ `${address2.street} ${address2.nr}, ${address2.zip} ${address2.city} (${address2.country})` }}</p>
      <p v-if="booking.asap && bookingPrice && bookingPrice.asap">{{ $t('booking.deliverytime') }}
        <b>{{ $t('booking.maxtime') }} {{ addMinutes(bookingPrice.asap.time, Math.round(bookingPrice.duration)) }}</b>
      </p>
      <p v-else-if="!booking.asap && bookingPrice &&bookingPrice.duration">
        {{ $t('booking.deliverytime') }}
        <b>{{ addMinutes(booking.time, Math.round(bookingPrice.duration)) }} -
          {{ addMinutes(booking.time, Math.round(bookingPrice.duration) + 30) }}</b>
      </p>
      <template v-if="bookingPrice && bookingPrice.price && bookingPrice.vehicletype && !isPriceLoading && !cargoError">
        <h2>Info</h2>
        <p>{{ $t('booking.vehicle') }}: <b>{{ bookingPrice.vehicletype.wagentype }}
          ({{ bookingPrice.vehicletype.wagenomschrijving[$i18n.locale] }})</b></p>
        <p>{{ $t('booking.traveltime') }}: <b>{{ Math.round(bookingPrice.duration) }} min</b></p>
        <p>{{ $t('booking.distance') }}: <b>{{ bookingPrice.distance }} km</b></p>
        <h2>{{ $t('booking.price') }}</h2>
        <p>{{ $t('booking.delivery') }}: <b>€{{ bookingPrice.price_details[0].cargo }}</b></p>
        <p v-if="booking.extras.load">{{ $t('booking.helpload') }}: <b>€{{ bookingPrice.vehicletype.ladenlossen }}</b>
        </p>
        <p v-if="booking.extras.unload">{{ $t('booking.helpunload') }}: <b>€{{
            bookingPrice.vehicletype.ladenlossen
          }}</b></p>
        <p v-if="booking.extras.paperinvoice">{{ $t('booking.paperinvoice') }}:
          <b>€{{ bookingPrice.vehicletype.paperinvoice }}</b></p>
        <p>{{$t('booking.vat')}}: <b>€{{bookingPrice.vat}}</b></p>
        <p class="booking__pricing__total">
          {{ $t('booking.totalprice') }}: <span>€{{ bookingPrice.price }}</span>
        </p>
        <router-link to="/pricing">{{ $t('pricing.link') }}</router-link>
      </template>
      <template v-else-if="booking.cargo.length && cargoError">
        <h2>{{ $t('booking.price') }}</h2>
        <p>{{ $t('booking.priceunknown') }}</p>
      </template>
      <div class="booking__pricing__loading" v-else-if="isPriceLoading">
        <img src="../../assets/image/loading.svg" alt="calculating your price"/>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue3-datepicker';
import SearchSelect from "@/components/SearchSelect";
import formatter from "@/mixins/formatter";
import ingenico from "@/mixins/ingenico";

export default {
  name: "Booking",

  mixins: [formatter, ingenico],
  components: {
    SearchSelect,
    DatePicker
  },

  data() {
    return {
      paymentError: null,
      isLoading: false,
      isPriceLoading: false,
      isOrdering: false,
      currentStep: 0,
      presetH: true,
      presetT: false,
      booking: {
        distance: 0,
        duration: 0,
        addresses: [],
        cargo: [],
        extras: {
          load: false,
          unload: false,
          paperinvoice: false
        },
        asap: false,
        time: "",
        date: "",
        user_id: "",
        payment: "CREDITCARD"
      },
      activeH: null,
      address1: {
        name: "",
        street: "",
        nr: "",
        zip: "",
        city: "",
        country: ""
      },
      streetH: null,
      streetsHeenTO: null,
      activeT: null,
      address2: {
        name: "",
        street: "",
        nr: "",
        zip: "",
        city: "",
        country: ""
      },
      streetT: null,
      streetsTerugTO: null,
      cargoModalVisible: false,
      cargoError: false,
      cargoItem: {
        aantal: 1,
        P: 'pakket',
        L: "",
        B: "",
        H: "",
        G: ""
      },
      calculateTimeout: null,
      minDate: null,
      minTime: null,
      gdprCompliant: false,
      termsCompliant: false,
      consentCompliant: false
    }
  },

  created() {
    this.$store.dispatch('booking/loadMaxValues');
    const date = new Date();
    date.setHours(date.getHours() + 6);
    this.minDate = date;
    this.booking.date = date;
    const coeff = 1000 * 60 * 5;
    const rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff);
    this.minTime = rounded.toLocaleTimeString('nl', {hour: "numeric", minute: "numeric"});
  },

  beforeUnmount() {
    clearTimeout(this.streetsHeenTO);
    clearTimeout(this.streetsTerugTO);
    clearTimeout(this.calculateTimeout);
    this.$store.dispatch('booking/clearBooking');
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    userId() {
      return this.$store.getters.userId;
    },
    activeUser() {
      return this.$store.getters['user/userData'];
    },
    userAddresses() {
      if (!this.activeUser) {
        return [];
      }
      return this.activeUser.addresses.filter(a => a.billoaddress);
    },

    zip1Valid() {
      const zip = this.address1.zip.toString();
      if (!zip) {
        return true;
      }
      return zip[0] !== '4' && zip[0] !== '5' && zip[0] !== '6' && zip[0] !== '7' && zip.length <= 4;
    },

    step1Valid() {
      return !Object.keys(this.address1).some(k => !this.address1[k]) && !Object.keys(this.address2).some(k => !this.address2[k]) && (this.booking.asap || !this.booking.asap && this.booking.date && this.booking.time) && this.zip1Valid;
    },

    step2Valid() {
      if (!this.booking.cargo.length) {
        return false;
      }
      return !this.cargoError && this.bookingPrice && this.gdprCompliant && this.termsCompliant && this.consentCompliant;
    },

    cargoItemValid() {
      const ci = this.cargoItem;
      const mv = this.maxValuesCargo;
      return ci.aantal > 0 && ci.naam && (ci.P === 'document' || ci.B && ci.B <= mv.B && ci.L && ci.L <= mv.L && ci.H && ci.H <= mv.H && ci.G && ci.G <= mv.G && ci.P);
    },

    maxValuesCargo() {
      return this.$store.getters['booking/maxValues'];
    },

    hasStreetsHeen() {
      return this.$store.getters['booking/hasStreetsHeen'];
    },

    streetsHeen() {
      return this.$store.getters['booking/streetsHeen'];
    },

    hasStreetsTerug() {
      return this.$store.getters['booking/hasStreetsTerug'];
    },

    streetsTerug() {
      return this.$store.getters['booking/streetsTerug'];
    },

    bookingPrice() {
      return this.$store.getters['booking/bookingPrice'];
    },

    timeSlots() {
      const bDate = this.booking.date;
      const mDate = this.minDate;
      if (bDate.getDate() === mDate.getDate() && bDate.getMonth() === mDate.getMonth() && bDate.getFullYear() === mDate.getFullYear()) {
        return this.generateTimeSlots(this.minTime);
      }
      return this.generateTimeSlots(null);
    }
  },

  watch: {
    currentStep() {
      this.$el.scrollIntoView();
    },

    presetH() {
      if (!this.presetH) {
        this.activeH = null;
        return;
      }
      if (this.userAddresses && this.userAddresses.length) {
        this.activeH = this.userAddresses.find(a => a.defaultaddress);
      }
    },
    activeH() {
      const ah = this.activeH;
      if (!ah) {
        this.address1 = {
          name: "",
          street: "",
          nr: "",
          zip: "",
          city: "",
          country: ""
        }
      } else {
        this.address1 = {
          name: ah.name,
          street: ah.street,
          nr: ah.streetnr,
          zip: ah.zip,
          city: ah.city,
          country: ah.country
        }
      }
    },
    presetT() {
      if (!this.presetT) {
        this.activeT = null;
      }
    },
    activeT() {
      const at = this.activeT;
      if (!at) {
        this.address2 = {
          name: "",
          street: "",
          nr: "",
          zip: "",
          city: "",
          country: ""
        }
      } else {
        this.address2 = {
          name: at.name,
          street: at.street,
          nr: at.streetnr,
          zip: at.zip,
          city: at.city,
          country: at.country
        }
      }
    },

    'address1.zip': function () {
      clearTimeout(this.streetsHeenTO);
      if(!this.presetH){
        this.address1.street = "";
        this.address1.nr = "";
        this.streetH = null;
      }
      this.streetsHeenTO = setTimeout(async () => {
        try {
          await this.$store.dispatch('booking/loadStreetsHeen', this.address1.zip);
        } catch (e) {
          console.log('invalid zip H');
        }
      }, 300);
    },
    'address2.zip': function () {
      clearTimeout(this.streetsTerugTO);
      if(!this.presetT){
        this.address2.street = "";
        this.address2.nr = "";
        this.streetT = null;
      }
      this.streetsTerugTO = setTimeout(async () => {
        try {
          await this.$store.dispatch('booking/loadStreetsTerug', this.address2.zip);
        } catch (e) {
          console.log('invalid zip T');
        }
      }, 300);
    },

    streetH() {
      if (!this.streetH) {
        this.address1.street = "";
        this.address1.city = "";
        this.address1.country = "";
        return;
      }
      this.address1.street = this.streetH.STRAAT;
      this.address1.city = this.streetH.STAD;
      this.address1.country = this.streetH.LAND;
    },
    streetT() {
      if (!this.streetT) {
        this.address2.street = "";
        this.address2.city = "";
        this.address2.country = "";
        return;
      }
      this.address2.street = this.streetT.STRAAT;
      this.address2.city = this.streetT.STAD;
      this.address2.country = this.streetT.LAND;
    },

    'booking.addresses': {
      deep: true,
      handler() {
        this.booking.duration = 0;
        this.booking.distance = 0;
        if (this.booking.addresses.length === 2 && this.booking.cargo.length) {
          this.loadBookingPrice();
        }
      }
    },
    'booking.extras': {
      deep: true,
      handler() {
        this.loadBookingPrice();
      }
    },
    'booking.cargo': {
      deep: true,
      handler() {
        if (this.booking.addresses.length === 2 && this.booking.cargo.length) {
          this.loadBookingPrice();
        }
      }
    },
  },

  methods: {
    generateTimeSlots(start) {
      const dt = new Date(1970, 0, 1);
      if (start) {
        const timeparts = start.split(':');
        dt.setHours(timeparts[0]);
        dt.setMinutes(timeparts[1]);
      }
      const timeslotsArray = [];
      while (dt.getDate() === 1) {
        timeslotsArray.push(dt.toLocaleTimeString('nl', {hour: "numeric", minute: "numeric"}));
        dt.setMinutes(dt.getMinutes() + 5);
      }
      this.booking.time = timeslotsArray[0];
      return timeslotsArray;

    },

    startBooking(asap) {
      if (!this.isAuthenticated) {
        return;
      }
      this.booking.asap = asap;
      if (this.userAddresses && this.userAddresses.length) {
        this.activeH = this.userAddresses.find(a => a.defaultaddress);
      }
      this.currentStep++;
    },

    streetHInputChanged(value) {
      this.streetH = value;
    },

    streetTInputChanged(value) {
      this.streetT = value;
    },

    submitStep(step) {
      if (this.isValid(step)) {
        if (step === 1) {
          this.booking.addresses = [this.address1, this.address2];
        }
        if (step !== 2) {
          this.currentStep++;
        } else {
          this.placeBooking();
        }
      }
    },

    isValid(step) {
      if (step === 1) {
        return this.step1Valid;
      } else if (step === 2) {
        return this.step2Valid;
      }
    },

    async loadBookingPrice() {
      clearTimeout(this.calculateTimeout);
      this.cargoError = null;
      this.isPriceLoading = true;
      const data = {...this.booking};
      if (data.date) {
        data.date = this.dateToSql(data.date);
      }
      try {
        await this.$store.dispatch('booking/loadPrice', data);
        this.booking.distance = this.bookingPrice.distance;
        this.booking.duration = this.bookingPrice.duration;
      } catch (error) {
        this.cargoError = error;
      }
      this.isPriceLoading = false;
      this.calculateTimeout = setTimeout(() => {
        this.loadBookingPrice();
      }, 5 * 60 * 1000);
    },

    addCargo(ptype) {
      let naam = 'Box';
      if(ptype === 'pallet'){
        naam = 'Pallet'
      }
      else if(ptype === 'document'){
        naam = 'Document'
      }
      this.cargoItem = {
        naam: naam,
        aantal: 1,
        P: ptype,
        L: ptype === 'pallet' ? 120 : "",
        B: ptype === 'pallet' ? 80 : "",
        H: "",
        G: ""
      };
      this.cargoModalVisible = true;
    },

    addCargoSubmit() {
      if (!this.cargoItemValid) {
        return;
      }
      const cargo = {...this.cargoItem};
      cargo.G = parseFloat(cargo.G);
      this.booking.cargo.push(cargo);
      this.cargoModalVisible = false;
    },

    removeCargo(id) {
      this.booking.cargo.splice(id, 1);
    },

    handleNumberInput(event, item, key, min, max) {

      let value = event.target.value;
      if (!parseFloat(value)) {
        item[key] = min;
      } else {
        if (max && parseFloat(value) > max) {
          item[key] = max;
        }
        if (min && parseFloat(value) < min) {
          item[key] = min;
        }
      }
    },

    addMinutes(time, minsToAdd) {
      return new Date(new Date("1970/01/01 " + time).getTime() + minsToAdd * 60000).toLocaleTimeString('en-UK', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
    },

    getBookingPost() {
      const booking = {...this.booking};
      booking.user_id = this.userId;
      booking.calcprice = this.bookingPrice;
      booking.date = this.dateToSql(booking.date);
      booking.language = this.$i18n.locale.toUpperCase();
      return booking;
    },

    async placeBooking() {
      clearTimeout(this.calculateTimeout);
      this.isOrdering = true;
      this.paymentError = null;
      try {
        const response = await this.$store.dispatch('booking/placeOrder', this.getBookingPost());
        this.preparePayment(response.id);
      } catch (e) {
        this.paymentError = e;
        this.isOrdering = false;
      }
    },

    preparePayment(bon) {
      const paymentSettings = this.prepareIngenicoForm(bon, this.activeUser.user.email, this.bookingPrice.price, this.$i18n.locale);
      if (this.booking.payment === 'INVOICE') {
        this.finishInvoiceOrder(bon);
        return;
      }
      this.finishCreditCardOrder(paymentSettings);
    },

    async finishInvoiceOrder(bon) {
      try {
        await this.$store.dispatch('booking/finishInvoiceOrder', bon);
        this.$router.push({path: 'order-confirmed', query: {orderID: bon}})
      } catch (e) {
        this.paymentError = e;
        this.isOrdering = false;
      }
    },

    finishCreditCardOrder(options) {
      const form = document.createElement("form");
      document.body.appendChild(form);
      form.method = "POST";
      form.action = options.actionUrl;
      form.id = "form-payment";

      const element1 = document.createElement("input");
      element1.name = "ACCEPTURL"
      element1.value = options.confirmUrl;
      element1.type = 'hidden'
      form.appendChild(element1);

      const element2 = document.createElement("input");
      element2.name = "AMOUNT"
      element2.value = options.price;
      element2.type = 'hidden'
      form.appendChild(element2);

      const element3 = document.createElement("input");
      element3.name = "CANCELURL"
      element3.value = options.cancelUrl;
      element3.type = 'hidden'
      form.appendChild(element3);

      const element4 = document.createElement("input");
      element4.name = "CURRENCY"
      element4.value = options.currency;
      element4.type = 'hidden'
      form.appendChild(element4);

      const element5 = document.createElement("input");
      element5.name = "DECLINEURL"
      element5.value = options.failUrl;
      element5.type = 'hidden'
      form.appendChild(element5);

      const element6 = document.createElement("input");
      element6.name = "EMAIL"
      element6.value = options.email;
      element6.type = 'hidden'
      form.appendChild(element6);

      const element7 = document.createElement("input");
      element7.name = "EXCEPTIONURL"
      element7.value = options.failUrl;
      element7.type = 'hidden'
      form.appendChild(element7);

      const element8 = document.createElement("input");
      element8.name = "LANGUAGE"
      element8.value = options.lang;
      element8.type = 'hidden'
      form.appendChild(element8);

      const element9 = document.createElement("input");
      element9.name = "ORDERID"
      element9.value = options.id;
      element9.type = 'hidden'
      form.appendChild(element9);

      const element10 = document.createElement("input");
      element10.name = "PSPID"
      element10.value = options.pspid;
      element10.type = 'hidden'
      form.appendChild(element10);

      const element11 = document.createElement("input");
      element11.name = "SHASIGN"
      element11.value = options.sign;
      element11.type = 'hidden'
      form.appendChild(element11);

      form.submit();
    }
  }
}
</script>