<template>
  <div class="page home">
    <h1>{{ $t('home.title') }}</h1>
    <div v-html="$t('home.text')"></div>
    <WaitingPage></WaitingPage>
    <Booking v-if="token"></Booking>
  </div>
</template>

<script>

import WaitingPage from "@/components/WaitingPage";
import Booking from "@/components/Booking/Booking";
export default {
  name: 'Home',
  components:{
    WaitingPage,
    Booking
  },
  computed:{
    token(){
      return this.$store.getters.token;
    }
  }
}
</script>
