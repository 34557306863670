<template>
  <div class="page page--wide login">
    <h1 class="title login__title">{{ $t('forgotpassword.title') }}</h1>
    <div class="login__form" v-if="isResetSent">
      <p>{{ $t('resetpassword.checkmail') }}</p>
    </div>
    <form action="" class="login__form" autocomplete="off" @submit.prevent="submitRequestReset" v-else>
      <div class="login__form__field">
        <label>{{ $t('booking.email') }}</label>
        <input class="input login__input" type="email"
               v-model="reset.email" :placeholder="$t('booking.email')" autocomplete="new-password"/>
      </div>
      <button class="button" :class="{'button--disabled':!reset.email}"
              type="button"
              @click="submitRequestReset">{{ $t('login.requestreset') }}
      </button>
      <p class="error login__error"><span v-if="resetError">{{ $t('forgotpassword.notfound') }}</span></p>
    </form>
  </div>
</template>

<script>
export default {
  name: "PasswordRecovery",

  data() {
    return {
      isResetSent: false,
      resetError: false,
      reset: {
        email: ""
      }
    };
  },

  methods: {
    async submitRequestReset() {
      this.resetError = false;
      if (!this.reset.email) {
        return;
      }
      const response = await this.$store.dispatch('user/recoverPassword', this.reset.email);
      if (response.status) {
        this.isResetSent = true;
        return;
      }
      this.resetError = true;
    }
  }
}
</script>