<template>
  <div class="page">
    <div class="page__block">
      <h2>{{ $t('orderconfirm.title') }}</h2>

      <p>{{ $t('orderconfirm.text1') }} {{ $route.query.orderID }} {{ $t('orderconfirm.text2') }}</p>

      <p v-if="$route.query.CN">{{ $t('booking.name') }}: {{ $route.query.CN }}</p>
      <p v-if="$route.query.PAYID">{{ $t('orderconfirm.ref') }}: {{ $route.query.PAYID }}</p>
      <p>{{ $t('backto') }}
        <router-link to="/">Home</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderConfirmed",

  mounted() {
    window.scrollTo(0, 0);
  },

  created() {
    if (!this.$route.query.orderID) {
      this.$router.push('/NotFound');
    }
  }
}
</script>