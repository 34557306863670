export default {
    async loadMaxValues(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/price/maxvalues?access_token=${token}`;

        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const responseData = await response.json();
        if(!response.ok || !responseData.status){
            const error = new Error(
                responseData.error_description || 'Failed to fetch max values.'
            );
            throw error;
        }
        context.commit('setMaxValues',responseData.data);
    },

    async loadCarTypes(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/price/listcartypes?access_token=${token}`;

        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const responseData = await response.json();
        if(!response.ok || !responseData.status){
            const error = new Error(
                responseData.error_description || 'Failed to fetch car types.'
            );
            throw error;
        }
        context.commit('setCarTypes',responseData.data);
    },

    async loadPrice(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/price/calcprice?access_token=${token}`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if(!response.ok || ! responseData.status){
            const error = new Error(
                responseData.data || 'Failed to calculate price.'
            );
            throw error;
        }
        context.commit('setBookingPrice', responseData.data);
    },

    clearBooking(context){
        context.commit('setBookingPrice', {});
        context.commit('setStreetsHeen', []);
        context.commit('setStreetsTerug', []);
    },

    async loadStreetsHeen(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/streets/zoekstraten?postcode=${payload}&access_token=${token}`;
        context.commit('setStreetsHeen', []);
        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const responseData = await response.json();
        if(!response.ok || !responseData.status){
            context.commit('setStreetsHeen',{data:{},metadata:{}});
            const error = new Error(
                responseData.error_description || 'Failed to fetch streets heen.'
            );
            throw error;
        }
        context.commit('setStreetsHeen',responseData);
    },

    async loadStreetsTerug(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/streets/zoekstraten?postcode=${payload}&access_token=${token}`;
        context.commit('setStreetsTerug', []);
        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const responseData = await response.json();
        if(!response.ok || !responseData.status){
            context.commit('setStreetsTerug',{data:{},metadata:{}});
            const error = new Error(
                responseData.error_description || 'Failed to fetch streets terug.'
            );
            throw error;
        }
        context.commit('setStreetsTerug',responseData);
    },

    async placeOrder(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/orders/neworder?access_token=${token}`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        const responseData = await response.json();
        if(!response.ok || !responseData.status){
            const error = new Error(
                responseData.error_description || 'Failed to place order.'
            );
            throw error;
        }
        return responseData.data;
    },
    async finishInvoiceOrder(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/invoiceorderok/${payload}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if(!response.ok || !responseData.status){
            const error = new Error(
                responseData.error_description || 'Failed to invoice order.'
            );
            throw error;
        }
    }
}