<template>
  <TheHeader></TheHeader>
  <router-view/>
  <TheFooter></TheFooter>
  <transition name="fade">
    <div class="cookie" v-if="cookieVisible">
      <div class="cookie__content">
        <p>{{$t('cookie.text')}}</p>
        <button class="button" @click="acceptCookie">{{ $t('cookie.accept') }}</button>
      </div>
    </div>
  </transition>
</template>
<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";

export default {
  components: {
    TheHeader,
    TheFooter
  },

  data() {
    return {
      versionTimeout: null,
      cookieVisible: false
    }
  },

  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  },

  created() {
    this.$store.dispatch('tryLogin');
    this.initVersionWatcher();
    if (!localStorage.getItem('cookie-accepted')) {
      this.cookieVisible = true;
    }
  },

  beforeUnmount() {
    clearTimeout(this.versionTimeout);
  },

  methods: {
    initVersionWatcher() {
      const current = parseInt(process.env.VERSION.replaceAll(".", ""));
      const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_VERSION_API_ENV}/version?name=BILLOEXPRESS`;
      fetch(url).then(res => res.json().then(data => {
        if (data.status && data.data && data.data.versie) {
          const latest = parseInt(data.data.versie.replaceAll(".", ""));
          if (latest > current && data.data.hotfix) {
            console.log('update required');
            location.reload();
          }
        } else {
          console.warn("could not fetch active version.");
        }
      }));
      this.versionTimeout = setTimeout(() => {
        this.initVersionWatcher();
      }, 15 * 60 * 1000);
    },
    acceptCookie() {
      localStorage.setItem('cookie-accepted', 'yes');
      this.cookieVisible = false;
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/app';
</style>
