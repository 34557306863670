<template>
  <div class="page page--wide login">
    <h1>Inloggen</h1>
    <form action="" class="login__form" @submit.prevent="submitLogin">
      <label for="username">Username</label>
      <input id="username" type="text" class="input" v-model="username">
      <label for="password">Password</label>
      <input id="password" type="password" class="input" v-model="password">
      <button class="button" :class="{'button--disabled':submitDisabled}" @click="submitLogin">Log in</button>
      <router-link to="/register">Nog geen account?</router-link> <router-link to="/password-recovery">Wachtwoord vergeten?</router-link>
      <p class="error" v-if="error">{{$t('login.error')}}</p>
    </form>
  </div>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      error: null,
      isLoading: false,
      username: "",
      password: ""
    }
  },

  computed: {
    submitDisabled() {
      return !(this.username && this.password);
    }
  },

  methods: {
    async submitLogin() {
      this.error = null;
      if(!(this.username && this.password)){
        return;
      }
      this.isLoading = true;
      const payload = {
        username: this.username,
        password: this.password
      };
      try{
        await this.$store.dispatch('login', payload);
        this.$router.replace('/account');
      }
      catch(err){
        this.error = err.message;
      }
    }
  }
}
</script>