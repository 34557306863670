export default{
    methods: {
        dateToSql(date) {
            return date.toISOString().slice(0,10);
        },
        dateToString(date) {
            const pDate = date.toISOString().slice(0,10);
            const parts = pDate.split('-');
            return `${parts[2]}/${parts[1]}/${parts[0]}`;
        }
    }
}